import React from "react";
import { HelperText } from "react-native-paper";

type ErrorCaptionProps = {
  visible: boolean;
  message: string;
};

const ErrorText: React.FC<ErrorCaptionProps> = (props) => {
  const { visible, message } = props;
  return (
    <HelperText type="error" visible={visible}>
      {message}
    </HelperText>
  );
};

export default ErrorText;
