import {
  limit,
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  QuerySnapshot,
  Unsubscribe,
  where,
} from "firebase/firestore";
import React, { useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { FirebaseCollections } from "../../firebase";
import {
  EnumSendMessageDtoType,
  Message,
  SendBroadcastDto,
  SendMessageDto,
} from "../../sdk";
import { SelectContextFn } from "../../types";
import { useFunction, useOnMount } from "../hooks";
import ChannelAPI from "../services/channel.service";
import { selectChannel, useChannelStore } from "./channel.provider";

interface Context {
  messages: QueryDocumentSnapshot<Message>[];
  sendMessage: (dto: SendMessageDto) => Promise<void>;
  sendBroadcast: (dto: SendBroadcastDto) => Promise<void>;
  setActiveExtension: (extension: ChatExtension) => void;
  activeExtension: ChatExtension;
}

interface ReactionMap {
  [key: string]: QueryDocumentSnapshot<Message>;
}

const chatContext = createContext<Context>({} as Context);

type MessagesProviderProps = {
  channelId: string;
};

type SnapshotMessage = QueryDocumentSnapshot<Message>;

export enum ChatExtension {
  None,
  Reactions,
  SuperReactions,
}

const ChatProvider: React.FC<MessagesProviderProps> = (props) => {
  const { channelId, children } = props;
  const previousMessageRef = useRef<SnapshotMessage[]>([]);
  const [messages, setMessages] = useState<SnapshotMessage[]>([]);
  const [activeExtension, setActiveExtension] = useState<ChatExtension>(
    ChatExtension.None
  );

  const subscriptionsRef = useRef<Unsubscribe[]>([]);

  useOnMount(() => {
    console.log("Chat provider mounted");
    connect();

    return () => {
      console.log("Chat Provider unmounted");
      disconnect();
    };
  });

  const connect = useFunction(() => {
    const messageSub = onSnapshot(
      query(
        FirebaseCollections.messageList(channelId),
        orderBy("createdAt", "desc"),
        where("type", "==", EnumSendMessageDtoType.Text),
        limit(10)
      ),
      (snapshot) => {
        const previousMessages = previousMessageRef.current;
        const newMessages = handleMessageChanges(snapshot, previousMessages);

        previousMessageRef.current = newMessages;
        setMessages(newMessages);
      }
    );

    subscriptionsRef.current.push(messageSub);
  });

  const disconnect = useFunction(() => {
    subscriptionsRef.current.forEach((sub) => sub());
    subscriptionsRef.current = [];
  });

  const sendMessage = useFunction(async (dto: SendMessageDto) => {
    await ChannelAPI.sendMessage(channelId, dto);
  });

  const sendBroadcast = useFunction(async (dto: SendBroadcastDto) => {
    await ChannelAPI.sendBroadcast(channelId, dto);
  });

  const setActiveExtensionFn = useFunction((extension: ChatExtension) =>
    setActiveExtension(extension)
  );

  return (
    <chatContext.Provider
      value={{
        messages,
        sendMessage,
        sendBroadcast,
        activeExtension,
        setActiveExtension: setActiveExtensionFn,
      }}
    >
      {children}
    </chatContext.Provider>
  );
};

export {
  ChatProvider,
  useChatActions,
  selectMessages,
  useChatStore,
  useSuperReactions,
  selectActiveChatExtension,
};

function useChatStore<T>(selector: SelectContextFn<T>) {
  return useContextSelector(chatContext, selector);
}

function useChatActions() {
  return useContextSelector(chatContext, (context) => ({
    sendMessage: context.sendMessage,
    sendBroadcast: context.sendBroadcast,
    setActiveChatExtension: context.setActiveExtension,
  }));
}

function selectMessages(selector: Context) {
  return selector.messages;
}

function selectActiveChatExtension(selector: Context) {
  return selector.activeExtension;
}

const handleMessageChanges = (
  snapshot: QuerySnapshot<Message>,
  previousMessages: QueryDocumentSnapshot<Message>[]
) => {
  const previous = [...previousMessages];
  const snapshotLength = snapshot.docs.length;

  // Snapshot still being filled
  if (snapshotLength > previous.length) {
    return snapshot.docs;
  }

  snapshot.docChanges().forEach((change) => {
    if (change.type === "added") {
      previous.unshift(change.doc);
    }
  });

  return previous;
};

function useSuperReactions() {
  const channel = useChannelStore(selectChannel);
  const isFirstSuperReactionSnapshot = useRef<boolean>(true);
  const superReactionRef = useRef<ReactionMap>({});
  const [superReactions, setSuperReactions] = useState<
    QueryDocumentSnapshot<Message>[]
  >([]);

  const subscriptionsRef = useRef<Unsubscribe[]>([]);

  useOnMount(() => {
    connect();

    return () => {
      disconnect();
    };
  });

  const connect = useFunction(() => {
    const superReactionsSub = onSnapshot(
      query(
        FirebaseCollections.messageList(channel.id),
        orderBy("createdAt", "desc"),
        where("type", "==", EnumSendMessageDtoType.SuperReaction),
        limit(1)
      ),
      { includeMetadataChanges: true },
      (snapshot) => {
        // Stop from loading first snapshot of reaction
        if (isFirstSuperReactionSnapshot.current) {
          isFirstSuperReactionSnapshot.current = false;
          return;
        }
        if (snapshot.docChanges({ includeMetadataChanges: true })) {
          snapshot
            .docChanges({ includeMetadataChanges: true })
            .forEach((change) => {
              if (change.type === "added") {
                superReactionRef.current[change.doc.id] = change.doc;
              }
            });
        }
        const superReactionList = Object.values(superReactionRef.current).map(
          (reaction) => reaction
        );

        setSuperReactions(superReactionList);
      }
    );

    subscriptionsRef.current.push(superReactionsSub);
  });

  const removeSuperReaction = useFunction((reactionId: string) => {
    if (superReactionRef.current[reactionId]) {
      delete superReactionRef.current[reactionId];
    }
    const superReactionList = Object.values(superReactionRef.current).map(
      (reaction) => reaction
    );

    setSuperReactions([...superReactionList]);
  });

  const disconnect = useFunction(() => {
    subscriptionsRef.current.forEach((sub) => sub());
    subscriptionsRef.current = [];
  });

  return { superReactions, removeSuperReaction };
}
