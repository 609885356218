import { Plugin, Context, PLAYBACK_EVENT, Controller } from "@bitwild/vxdk";

import { ControlsLock } from "./controls-lock";
import type {
  CommandDto,
  ControllerCommandDto,
  PluginCommandDto,
  SyncPlayerCommandDto,
  TimeCommandDto,
} from "./conductor.dto";
import { CommandType } from "./conductor.dto";

type ConductorConfig = {
  commands?: CommandDto[];
};
export class ConductorPlugin extends Plugin<ConductorConfig> {
  public name = "conductor";

  private conductor: ControlsLock;
  private _queue: TimeCommandDto[] = [];

  // Timer used to set playback rate back to normal after a while
  private _timer: any;

  // Average time difference between sync commands
  private _averageTimeDiff: number[] = [];
  // Check if syncing is happening
  private _isSyncing = false;

  constructor(ctx: Context) {
    super(ctx);
    this.conductor = new ControlsLock(ctx.controller);

    const { commands } = this.getConfig();

    if (commands) {
      // Filter out commands that do not have `when`
      const unsortedQueue = commands.filter((command) =>
        this._isTimelyCommand(command)
      ) as TimeCommandDto[];

      this._queue = unsortedQueue.sort((a, b) => a.timestamp - b.timestamp);
    }
  }

  connectedCallback() {
    this.bindEvents();
  }

  bindEvents() {
    this.on(PLAYBACK_EVENT.TIMEUPDATE, () => {
      const currentTime = this.controller.getCurrentTime();

      this._next(currentTime);
    });
  }

  lockControls() {
    this.conductor.lockControls();
  }

  unlockControls() {
    this.conductor.unlockControls();
  }

  isLocked() {
    return this.conductor.isLocked();
  }

  addCommand(command: TimeCommandDto) {
    const index = this._queue.findIndex(
      (item) => item.timestamp > command.timestamp
    );
    this._queue.splice(index, 0, command);
  }

  removeCommand(command: TimeCommandDto) {
    const index = this._queue.findIndex((item) => item === command);
    if (index >= 0) {
      this._queue.splice(index, 1);
    }
  }

  async _next(time: number) {
    const nextCmd = this._queue[0];

    if (nextCmd && nextCmd.timestamp <= time) {
      const command = this._queue.shift();
      if (command) {
        await this.runCommand(command);
      }
      this._next(time);
    }
  }

  _isPluginCommand(command: CommandDto): command is PluginCommandDto {
    return (command as PluginCommandDto).type === CommandType.PLUGIN;
  }

  _isPlaybackCommand(command: CommandDto): command is ControllerCommandDto {
    return (command as ControllerCommandDto).type === CommandType.CONTROLLER;
  }

  _isTimelyCommand(command: CommandDto): command is TimeCommandDto {
    return (command as CommandDto).timestamp != null;
  }

  async runSync(command: SyncPlayerCommandDto) {
    // Check if its playing or not
    const isPlaying = this.controller.isPlaying();
    const volume = this.controller.getVolume();
    const muted = this.controller.isMuted();

    // Do not try to sync the player if its waiting for user interaction
    if (this.controller.getState().waitingForUser) {
      return;
    }

    // If its buffering do not force commands
    if (this.controller.getState().buffering) {
      if (this.controller.getPlaybackRate() !== 1) {
        this.controller.setPlaybackRate(1);
      }
      return;
    }

    if (
      command.player.lockControls &&
      command.player.lockControls !== this.isLocked()
    ) {
      if (command.player.lockControls) {
        this.lockControls();
      } else {
        this.unlockControls();
      }
    } else {
      this.unlockControls();
    }
    const playback = this.controller.getPlaybackAdapter();

    if (command.player.muted && command.player.muted !== muted) {
      if (command.player.muted) {
        playback.mute();
      } else {
        playback.unmute();
      }
    }

    if (command.player.volume && command.player.volume !== volume) {
      playback.setVolume(command.player.volume);
    }

    if (command.player.playing && !isPlaying) {
      await playback.play();
    } else if (!command.player.playing && isPlaying) {
      playback.seekTo(command.player.currentTime);
      playback.pause();
      return;
    }

    const latency = (command.clientReceivedAt ?? 0) - command.originSentAt;

    // Adds estimated latency to the average time difference
    // Difference is in milliseconds while currentTime is value in seconds
    const syncTime = command?.player?.currentTime + latency / 1000;

    this.trySync(syncTime);
  }

  trySync(syncTime: number) {
    // This is the time that will force a sync/seekTo
    const syncUpThreshold = 4;
    // Threshold to force a playback speed change for catch up
    // If its syncing it should continue to catch up until it gets closer
    const catchUpThreshold = this._isSyncing ? 0.1 : 0.3;

    const currentTime = this.controller.getCurrentTime();
    // Time difference
    let timeDiff = currentTime - syncTime;

    // Absolute time differnce
    let absTimeDiff = Math.abs(timeDiff);

    // If its too far behind or ahead, we need seekTo time
    if (absTimeDiff > syncUpThreshold) {
      // Seek to a bit behind seek time
      this.controller.seekTo(syncTime);
      this._averageTimeDiff = [];
      return;
    }

    // If sample size is less than 3, or if its not syncing, collect sample
    // If its syncing we need move forward without having to collect the sample to keep a more agressive sync
    if (this._averageTimeDiff.length <= 3 && this._isSyncing === false) {
      this._averageTimeDiff.push(timeDiff);
      return;
    } else {
      // Average timediff
      // If there is a value to average
      if (this._averageTimeDiff.length !== 0) {
        const averages = this._averageTimeDiff;

        timeDiff = averages.reduce((a, b) => a + b, 0) / averages.length;

        absTimeDiff = Math.abs(timeDiff);

        this._averageTimeDiff = [];
      }
    }

    const isBehind = Math.sign(timeDiff) === -1;

    const calculateCatchSpeed = () => {
      // Far apart if time is greater than 1 second
      const isFarApart = absTimeDiff > 1;

      console.log("Time diff", absTimeDiff);

      const closeCatchSpeed = isBehind ? 1.1 : 1;
      const farCatchSpeed = isBehind ? 1.5 : 0.9;

      const catchSpeed = isFarApart ? farCatchSpeed : closeCatchSpeed;

      console.log("is far", isFarApart);

      console.log("isBehind", isBehind);

      return catchSpeed;
    };

    const catchSpeed = calculateCatchSpeed();

    const scheduleNormalizePlayback = (isCaughtUp = false) => {
      clearTimeout(this._timer);

      this._timer = setTimeout(
        () => {
          // Schedule to set playback back to normal
          // To avoid playback rate getting stuck
          if (this.controller.getPlaybackRate() !== 1) {
            console.log("Normalizing playback");
            this.controller.setPlaybackRate(1);
          }
          /// If its catching up, add long timer in case it hangs
          /// If its not catching up, add short timer
        },
        isCaughtUp ? 150 : 6000
      );
    };

    const needCatchUp = absTimeDiff > catchUpThreshold;

    this._isSyncing = needCatchUp;
    // If its close, we will try to sync
    if (needCatchUp) {
      if (isBehind) {
        if (this.controller.getPlaybackRate() !== catchSpeed) {
          // To avoid keeping changing playback
          // Only set if its already 0
          if (this.controller.getPlaybackRate() === 1) {
            console.log("Catch up speed", catchSpeed);
            this.controller.setPlaybackRate(catchSpeed);
          }
        }
      } else {
        this.controller.pause();

        setTimeout(() => {
          this.controller.play();
        }, timeDiff * 1000);
      }
    }
    // Schedule to set playback back to normal
    // If does not need catch up, just clear timer.
    scheduleNormalizePlayback(needCatchUp === false);
  }

  async runCommand(command: CommandDto) {
    if (this._isPlaybackCommand(command)) {
      const caller = this.controller[
        command.action as keyof Controller
      ] as unknown;

      if (caller instanceof Function) {
        await caller.call(this.controller, command.arguments);
      }
      return;
    }

    if (this._isPluginCommand(command)) {
      const plugin = this.controller.getPluginByName(command.pluginName);

      if (plugin) {
        const caller = plugin[command.action as keyof Plugin] as unknown;

        if (caller instanceof Function) {
          await caller.call(plugin, command.arguments);
        }
      }
    }
  }

  //   disconnectedCallback() {}
}
