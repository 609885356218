import React from "react";
import { Appbar } from "react-native-paper";
import {
  selectIsPresenter,
  useChannelStore,
} from "../../client/providers/channel.provider";
import { Row } from "../../helpers/layoutPrimitives";
import ConductorControls from "../../modules/conductor/components/ConductorControls";
import { theme } from "../../theme";

const BottomBar: React.FC = () => {
  const isPresenter = useChannelStore(selectIsPresenter);
  return (
    <Appbar style={{ backgroundColor: theme.custom.darkGray }}>
      <Row flex={1}>
        <ConductorControls />
      </Row>
    </Appbar>
  );
};

export default BottomBar;
