import { MotiView } from "moti";
import React, { useMemo } from "react";
import { Text } from "react-native";
import { useScreenSize } from "../../../client/hooks";

type ReactEmojiProps = {
  emoji: string;
  width: number;
};

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

const ReactEmoji: React.FC<ReactEmojiProps> = (props) => {
  const { emoji, width } = props;
  const { isSmall } = useScreenSize();
  const translateX = getRandomInt(40);
  const translateY = getRandomInt(isSmall ? 20 : 60);
  const iconSize = useMemo(() => {
    return 24 - getRandomInt(14);
  }, []);

  const animationWidth = -width / (isSmall ? 1.5 : 2);

  const sign = translateX % 2 == 0 ? "-" : "";
  const rotateZ = `${sign}90deg`;
  return (
    <MotiView
      style={{
        position: "absolute",
        right: 0,
        bottom: 0,
        zIndex: 999,
      }}
      onDidAnimate={() => null}
      transition={{ type: "timing", duration: 3000 }}
      from={{
        opacity: 1,
        translateX: translateX,
        translateY: translateY,
        rotateZ: "0deg",
      }}
      animate={{
        opacity: 0,
        translateX: animationWidth,
        rotateZ: rotateZ,
        // left: 0,
      }}
    >
      <Text style={{ fontSize: iconSize }}>{emoji}</Text>
    </MotiView>
  );
};

export default ReactEmoji;
