import { MotiView, View } from "moti";
import { useLayout } from "../../hooks/useLayout";
type AnimateDisplaySizeProps = {
  duration?: number;
};
export const AnimateDisplaySize: React.FC<AnimateDisplaySizeProps> = (
  props
) => {
  const { height, onLayout } = useLayout();
  return (
    <MotiView
      animate={{ height }}
      transition={{
        type: "timing",
        duration: props.duration ?? 350,
      }}
      style={{ overflow: "hidden" }}
    >
      <View onLayout={onLayout}>{props.children}</View>
    </MotiView>
  );
};

export const AnimateDisplay: React.FC = (props) => {
  return (
    <MotiView
      from={{
        opacity: 0,
        scale: 0.9,
      }}
      animate={{
        opacity: 1,
        scale: 1,
      }}
      exit={{
        opacity: 0,
        scale: 0.9,
      }}
    >
      {props.children}
    </MotiView>
  );
};
