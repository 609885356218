import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { getContrast } from "../../../helpers/colors";
import { Container } from "../../../helpers/layoutPrimitives";
import { theme } from "../../../theme";

const ScrollToBottomButton = () => {
  return (
    <Container
      //   backgroundColor={theme.colors.primary}
      height={24}
      width={24}
      borderRadius={12}
      center
    >
      <Ionicons
        name="arrow-down-outline"
        color={getContrast(theme.colors.primary)}
        size={16}
      />
    </Container>
  );
};

export default ScrollToBottomButton;
