/* eslint-disable no-underscore-dangle, no-use-before-define */

import React from "react";
import { View } from "react-native";
import { Day, IMessage, MessageProps, utils } from "react-native-gifted-chat";
import { Row, Spacer16, Spacer8 } from "../../../helpers/layoutPrimitives";
import Bubble from "./Bubble";

const { isSameUser, isSameDay } = utils;

const CustomMessage: React.FC<MessageProps<IMessage>> = (props) => {
  const { containerStyle, ...rest } = props;

  function renderDay() {
    if (props.currentMessage.createdAt) {
      const { containerStyle, ...rest } = props;
      if (props.renderDay) {
        return props.renderDay(rest);
      }
      return <Day {...rest} />;
    }
    return null;
  }

  function renderBubble() {
    const { containerStyle, ...rest } = props;
    if (props.renderBubble) {
      return props.renderBubble(rest);
    }
    return <Bubble {...rest} />;
  }

  const marginBottom = isSameUser(props.currentMessage, props.nextMessage)
    ? 2
    : 10;

  // if (props.currentMessage.system === true) {
  //   const { containerStyle, ...rest } = props;
  //   return renderSystemMessage();
  // }

  return (
    <View>
      {renderDay()}
      <Row marginBottom={marginBottom}>
        <Spacer16 />
        {props.renderAvatar(props)}
        <Spacer8 />
        {renderBubble()}
      </Row>
    </View>
  );
};

export default CustomMessage;
