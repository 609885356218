import { Portal } from "@gorhom/portal";
import { Timestamp } from "firebase/firestore";
import React, { Fragment, useRef, useState } from "react";
import {
  default as ConfettiCannon,
  default as Explosion,
} from "react-native-confetti-cannon";
import { ActionType } from "../../../client/dto/actions.dto";
import {
  selectIsModerator,
  useChannelStore,
} from "../../../client/providers/channel.provider";
import { useConductor } from "../../../client/providers/conductor.provider";
import { useVxdkController } from "../../../client/providers/vxdk.provider";
import { ConductorPlugin } from "../../../conductor/conductor";
import { PortalLocation } from "../../../helpers/portals";

const ConductorListener: React.FC = (props) => {
  const syncData = useConductor((context) => context.syncData);
  const isModerator = useChannelStore(selectIsModerator);
  const actionData = useConductor((context) => context.actionData);
  const confettiRef = useRef<Explosion>();
  const [showConfetti, setShowConfetti] = useState(false);

  const controller = useVxdkController();

  React.useEffect((): void => {
    if (controller && !isModerator) {
      const conductor = controller.getPlugin(ConductorPlugin);

      if (syncData) {
        // Do not override volume
        syncData.clientReceivedAt = Timestamp.now().toMillis();
        delete syncData.player.volume;
        conductor.runSync(syncData);
      }
    }
  }, [syncData, controller, isModerator]);

  React.useEffect(() => {
    if (!actionData) return;
    if (actionData.type === ActionType.CONFETTI) {
      setShowConfetti(true);
      setTimeout(() => {
        confettiRef.current.start();
      }, 20);
    }
  }, [actionData]);

  return (
    <Fragment>
      <Portal hostName={PortalLocation.ROOT}>
        {showConfetti && (
          <ConfettiCannon
            count={30}
            origin={{ x: 0, y: 0 }}
            autoStart={false}
            // colors={[Colors.black, Colors.red600, Colors.white]}
            fadeOut={true}
            onAnimationEnd={() => setShowConfetti(false)}
            // fallSpeed={1000}
            ref={confettiRef}
          />
        )}
      </Portal>
    </Fragment>
  );
};

export default ConductorListener;
