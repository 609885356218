import type { NextFn } from "../../common/hooks";

import type { Controller } from "../../context/controller";

export class ControlsLock {
  private controlsLocked: boolean;
  constructor(controller: Controller) {
    this.controlsLocked = false;
    controller.hook.before("play", this.onPlay.bind(this));
    controller.hook.before("pause", this.onPause.bind(this));
    // this.controller.hooks.attach("setVolume", this.onSetVolume.bind(this));
    controller.hook.before("seekTo", this.onSeekTo.bind(this));
  }

  lockControls() {
    this.controlsLocked = true;
  }

  unlockControls() {
    this.controlsLocked = false;
  }

  isLocked() {
    return this.controlsLocked;
  }

  onPlay(next: NextFn) {
    if (!this.isLocked()) {
      next();
    }
  }
  onPause(next: NextFn) {
    if (!this.isLocked()) {
      next();
    }
  }

  onSeekTo(next: NextFn) {
    if (!this.isLocked()) {
      next();
    }
  }
}

// const lockedMessage = (message: string) => {
//   return "Controls are locked cannot perform: " + message;
// };
