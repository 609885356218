import React from "react";
import {
  createContext,
  useContext,
  useContextSelector,
} from "use-context-selector";

type Context = {
  loading: boolean;
  setLoading: (loading: boolean) => void;
};
const loadingContext = createContext<Context>({
  loading: false,
  setLoading: () => {},
} as Context);

/// Handles all business logic for canvas drawing
const LoadingProvider: React.FC = (props) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <loadingContext.Provider value={{ loading, setLoading }}>
      {props.children}
    </loadingContext.Provider>
  );
};

function useLoading<T>() {
  return useContext(loadingContext);
}

function useLoadingSelector<T>(selector: (context: Context) => T) {
  return useContextSelector(loadingContext, selector);
}

export { LoadingProvider, useLoading, useLoadingSelector };
