import React from "react";
import { Button, Text } from "react-native-paper";
import {
  selectIsModerator,
  useChannelStore,
} from "../../client/providers/channel.provider";
import { useChatActions } from "../../client/providers/chat.provider";
import { useVxdkController } from "../../client/providers/vxdk.provider";
import { Container, Row } from "../../helpers/layoutPrimitives";

const emojiReactions = ["😂", "❤️", "👍", "😥", "👎", "😡"];

const ReactionSelector = () => {
  const { sendBroadcast } = useChatActions();
  const isModerator = useChannelStore(selectIsModerator);
  const controller = useVxdkController();

  const onSend = async (emoji: string) => {
    await sendBroadcast({
      data: { emoji },
      mediaMetadata: {
        timestamp: controller.getCurrentTime(),
      },
      type: "REACTION",
    });
  };

  React.useEffect(() => {
    if (isModerator) {
      const interval = setInterval(() => {
        onSend(
          emojiReactions[Math.floor(Math.random() * emojiReactions.length)]
        );
      }, 200);

      return () => clearInterval(interval);
    }
  }, [isModerator]);

  const width = 100 / emojiReactions.length + "%";
  return (
    <Container>
      <Row flex={1} justifyContent="space-between">
        {emojiReactions.map((emoji) => (
          <Container key={emoji} width={width}>
            <Button onPress={() => onSend(emoji)}>
              <Text style={{ fontSize: 28 }}>{emoji}</Text>
            </Button>
          </Container>
        ))}
      </Row>
    </Container>
  );
};

export default ReactionSelector;
