import type { Controller } from "@bitwild/vxdk";

export interface ConductorOptions {
  commands: CommandDto[];
}

export interface CommandDto {
  type: CommandType;
  timestamp?: number;
  action?: string;
  arguments?: any[];
}

export interface SourcePlayerDto {
  currentTime: number;
  playing: boolean;
  volume: number;
  muted: boolean;
  lockControls: boolean;
}

export interface SyncPlayerCommandDto {
  player: SourcePlayerDto;
  originSentAt: number;
  serverReceivedAt: number;
  clientReceivedAt?: number;
}

export interface TimeCommandDto extends CommandDto {
  timestamp: number;
}

export interface PluginCommandDto extends CommandDto {
  type: CommandType.PLUGIN;
  pluginName: string;
}

export interface ControllerCommandDto extends CommandDto {
  type: CommandType.CONTROLLER;
  action: keyof Controller;
}

export enum CommandType {
  CONTROLLER = "CONTROLLER",
  PLUGIN = "PLUGIN",
}
