import { GiphyFetch } from "@giphy/js-fetch-api";
import { IGif } from "@giphy/js-types";
import {
  Grid,
  SearchBar,
  SearchContext,
  SearchContextManager,
} from "@giphy/react-components";
import * as React from "react";
import { Fragment } from "react";
import { Image, ScrollView, View } from "react-native";
import {
  Button,
  Modal,
  Paragraph,
  Portal,
  Surface,
  Title,
} from "react-native-paper";
import { useScreenSize } from "../../client/hooks";
import {
  selectChannel,
  selectIsModerator,
  useChannelStore,
} from "../../client/providers/channel.provider";
import { useChatActions } from "../../client/providers/chat.provider";
import { useVxdkController } from "../../client/providers/vxdk.provider";
import {
  Column,
  Padding,
  Row,
  Spacer16,
  Spacer8,
} from "../../helpers/layoutPrimitives";
import { useLayout } from "../../hooks/useLayout";
import { EnumSendMessageDtoType } from "../../sdk";
import { theme } from "../../theme";
import TextField from "../atoms/TextField";

const API_KEY = "xVzkfc695tuIF5IesiiRfLWSMk0PwCiH";
const gf = new GiphyFetch(API_KEY);

const GiphyView = () => (
  <SearchContextManager
    initialTerm="celebration"
    theme={{ mode: "light" }}
    options={{ sort: "relevant", lang: "pt", type: "stickers" }}
    apiKey={API_KEY}
  >
    <GiphySearch />
  </SearchContextManager>
);

const GiphySearch: React.FC = (props) => {
  const { height, width, onLayout } = useLayout();
  const channel = useChannelStore(selectChannel);
  const isModerator = useChannelStore(selectIsModerator);
  const { fetchGifs, searchKey } = React.useContext(SearchContext);
  const { isSmall } = useScreenSize();

  const [visible, setVisible] = React.useState(false);
  const [selected, setSelected] = React.useState<string>();

  // fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
  // if this function changes, change the Grid key to recreate the grid and start over
  // see the codesandbox for a runnable example
  // const fetchGifs = (offset: number) =>
  //   gf.search(searchTerm, { offset, limit: 10 });

  const onSend = async (item: IGif, e: any) => {
    e.preventDefault();

    // const rendition = "fixed_width_small";
    const rendition = "fixed_width";

    // const imageKeys = Object.keys(item.images);
    const firstSelection = item.images[rendition];

    setSelected(firstSelection.url);
    setVisible(true);
  };
  return (
    <Fragment>
      <SuperReactionModal
        visible={visible}
        imageUrl={selected}
        onDismiss={() => setVisible(false)}
      />
      <View style={{ flex: 1 }} onLayout={onLayout}>
        <SearchBar placeholder="Search Reactions" />

        <ScrollView
          style={{
            backgroundColor: theme.custom.darkGray,
            borderColor: theme.custom.darkGray,
            padding: 20,
            borderWidth: 1,
            minHeight: height,
          }}
          onLayout={onLayout}
        >
          <Grid
            width={width - 40}
            columns={3}
            gutter={20}
            onGifClick={onSend}
            // borderRadius={25}
            backgroundColor={"transparent"}
            fetchGifs={fetchGifs}
            hideAttribution={true}
            key={searchKey}
          />
        </ScrollView>
      </View>
    </Fragment>
  );
};

export default GiphyView;

type SuperReactionModalProps = {
  visible: boolean;
  imageUrl: string;
  onDismiss: () => void;
};
const SuperReactionModal: React.FC<SuperReactionModalProps> = (props) => {
  const { visible, imageUrl, onDismiss } = props;
  const containerStyle = { width: 350, borderRadius: 25 };
  const [message, setMessage] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);

  const { sendMessage } = useChatActions();
  const controller = useVxdkController();

  const handleOnDismiss = () => {
    setMessage("");
    onDismiss();
  };

  const handleOnSubmit = React.useCallback(async () => {
    try {
      setLoading(true);

      await sendMessage({
        content: message,
        type: EnumSendMessageDtoType.SuperReaction,
        metadata: {
          timestamp: controller.getCurrentTime(),
          imageUrl: imageUrl,
        },
      });
    } catch (err) {
    } finally {
      setLoading(false);
      handleOnDismiss();
    }
  }, [message, imageUrl, handleOnDismiss]);

  return (
    <Portal>
      <Modal visible={visible} onDismiss={onDismiss}>
        <Row justifyContent="center">
          <Surface style={containerStyle}>
            <Padding size={24}>
              <Column alignItems="center">
                <Image
                  source={{
                    uri: imageUrl,
                  }}
                  resizeMode="contain"
                  style={{
                    height: 125,
                    width: 125,
                  }}
                />
                <Title style={{ fontWeight: "bold" }}>Super Reaction!</Title>
                <Spacer8 />
                <Paragraph style={{ textAlign: "center" }}>
                  You have chosen a Super Reaction. Your message will be
                  displayed for everyone.
                </Paragraph>
                <Spacer16 />
                <Row width="100%">
                  <TextField
                    label="Write your message"
                    value={message}
                    disabled={loading}
                    onChangeText={(text) => setMessage(text)}
                    onSubmitEditing={handleOnSubmit}
                  />
                </Row>
                <Spacer16 />
                <Row width="100%" justifyContent="flex-end">
                  <Button onPress={handleOnDismiss}>Cancel</Button>
                  <Spacer8 />
                  <Button
                    mode="contained"
                    loading={loading}
                    onPress={handleOnSubmit}
                  >
                    Send
                  </Button>
                </Row>
              </Column>
            </Padding>
          </Surface>
        </Row>
      </Modal>
    </Portal>
  );
};
