import { Options } from "@bitwild/vxdk";
import "@bitwild/vxdk/dist/style.css";
import React from "react";
import { View } from "react-native";
import { useVxdkContext } from "../../client/providers/vxdk.provider";

type VxdkViewProps = {
  options: Partial<Options>;
  width: number;
  height: number;
};
const VxdkView: React.FC<VxdkViewProps> = ({ options, width, height }) => {
  const containerRef = React.useRef(null);

  const { load, unload, controller } = useVxdkContext((c) => ({
    load: c.load,
    unload: c.unload,
    controller: c.controller,
  }));

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      !controller && load(containerRef, options);
    }

    return () => controller && unload();
  }, [controller, unload, options]);

  return (
    <View
      ref={containerRef}
      style={{
        width,
        maxHeight: height,
      }}
    />
  );
};

export default VxdkView;
