import { isEmpty, throttle } from "lodash";
import React from "react";
import { LayoutChangeEvent, View } from "react-native";
import { IconButton } from "react-native-paper";
import {
  CanvasPath,
  ReactSketchCanvas,
  ReactSketchCanvasRef,
} from "react-sketch-canvas";
import { getUserNameColor } from "../../../client/helpers/utils";
import { usePrevious } from "../../../client/hooks";
import {
  selectIsPresenter,
  useChannelStore,
} from "../../../client/providers/channel.provider";
import {
  DrawingProvider,
  useDrawingProvider,
} from "../../../client/providers/drawing.provider";
import { Column, Row, Spacer8 } from "../../../helpers/layoutPrimitives";
import { useUser } from "../../user/user.provider";

const DrawCanvas: React.FC = () => {
  const canvasRef = React.useRef<ReactSketchCanvasRef>();
  const containerRef = React.useRef<View>();
  const previousStartTime = usePrevious(0);

  const displayName = useUser((s) => s.user.displayName);

  const { loadCanvas, savePath, undo, redo, eraseCanvas } = useDrawingProvider(
    (state) => ({
      loadCanvas: state.loadCanvas,
      savePath: state.savePath,
      undo: state.undo,
      redo: state.redo,
      eraseCanvas: state.eraseCanvas,
    })
  );

  const isPresenter = useChannelStore(selectIsPresenter);

  const handleOnLayout = React.useCallback(
    (event: LayoutChangeEvent) => {
      if (!canvasRef.current) return;
      loadCanvas(canvasRef.current, event.nativeEvent.layout);
    },
    [loadCanvas]
  );

  const handleChange = React.useCallback(
    throttle(async (paths: CanvasPath[]) => {
      if (!isPresenter || isEmpty(paths)) return;
      const lastElement = [...paths]?.pop();

      if (lastElement.endTimestamp > 0) {
        return;
      }
      await savePath(lastElement);
    }, 100),
    [isPresenter, savePath]
  );

  const handleFinish = React.useCallback(
    async (path: CanvasPath) => {
      // TODO: implement better logic of who started the action and block it
      if (!isPresenter) return;

      await savePath(path);
    },
    [isPresenter, savePath]
  );

  return (
    <View
      ref={containerRef}
      onLayout={handleOnLayout}
      pointerEvents={isPresenter ? "auto" : "none"}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        zIndex: 4,
      }}
    >
      {isPresenter && (
        <Column position="absolute" right={0} alignItems="flex-end" zIndex={9}>
          <Row justifyContent="flex-end">
            <IconButton icon="undo" onPress={undo} />
            <Spacer8 />
            <IconButton icon="redo" onPress={redo} />
            <Spacer8 />
            <IconButton icon="eraser" onPress={eraseCanvas} />
            <Spacer8 />
          </Row>
        </Column>
      )}

      <ReactSketchCanvas
        ref={canvasRef}
        strokeColor={getUserNameColor(displayName)}
        strokeWidth={5}
        withTimestamp={true}
        style={{
          position: "relative",
          flex: 1,
        }}
        canvasColor="transparent"
        onStroke={handleFinish}
        onChange={handleChange}
      />
    </View>
  );
};

export default () => (
  <DrawingProvider>
    <DrawCanvas />
  </DrawingProvider>
);
