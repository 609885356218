import React, { useState } from "react";
import { Title } from "react-native-paper";
import {
  Column,
  Container,
  Row,
  Spacer16,
} from "../../helpers/layoutPrimitives";
import Button from "../atoms/Button";
import TextField from "../atoms/TextField";
type PasscodeFormProps = {
  onSubmit: (value: string) => void;
};
const PasscodeForm: React.FC<PasscodeFormProps> = (props) => {
  const { onSubmit } = props;
  const [passcode, setPasscode] = useState("");

  return (
    <Container flex={1} center>
      <Column maxWidth={250}>
        <Title style={{ textAlign: "center" }}>
          You need a passcode to Join this channel
        </Title>
        <Spacer16 />
        <Row>
          <TextField
            label="Passcode"
            value={passcode}
            keyboardType="number-pad"
            onChangeText={setPasscode}
            onSubmitEditing={() => onSubmit(passcode)}
          />
        </Row>
        <Spacer16 />
        <Button onPress={() => onSubmit(passcode)} icon="arrow-forward" reverse>
          Join
        </Button>
      </Column>
    </Container>
  );
};

export default PasscodeForm;
