import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { useWindowDimensions, View } from "react-native";
import {
  NavigationState,
  SceneMap,
  SceneRendererProps,
  TabBar,
  TabView,
} from "react-native-tab-view";
import { Column } from "../../helpers/layoutPrimitives";
import ChatView from "../../modules/channel/components/ChatView";
import { theme } from "../../theme";
import { IconNames } from "../../types";
import ParticipantCountView from "../molecules/ParticipantCountView";
import BottomView from "./BottomView";
import GiphySearch from "./GiphyView";
import MobileSyncView from "./MobileSyncView";
import ParticipantsList from "./ParticipantsList";

const renderScene = SceneMap({
  chat: ChatView,
  participants: ParticipantsList,
  reactions: GiphySearch,
  mobile: MobileSyncView,
});

type SideViewProps = {
  width: string | number;
  tabBarPosition?: "top" | "bottom";
};

const SideView: React.FC<SideViewProps> = (props) => {
  const layout = useWindowDimensions();

  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: "chat", title: "Chat", icon: "chat" },
    { key: "participants", title: "Participants", icon: "users" },

    { key: "mobile", title: "Mobile Sync" },
  ]);

  const getTabIcon = (route: string, focused: boolean): IconNames => {
    let iconName: string;
    if (route === "chat") {
      return focused ? "chatbubble-ellipses-sharp" : "chatbubble-outline";
    }

    if (route === "participants") {
      return focused ? "people-sharp" : "people-outline";
    }

    if (route === "mobile") {
      return focused ? "qr-code" : "qr-code-outline";
    }
  };

  const renderTabBar = (
    props: SceneRendererProps & { navigationState: NavigationState<any> }
  ) => (
    <TabBar
      {...props}
      renderIcon={({ route, focused, color }) => (
        <Ionicons
          name={getTabIcon(route.key, focused)}
          color={color}
          size={24}
        />
      )}
      getLabelText={({ route }) => ""}
      indicatorStyle={{ backgroundColor: theme.colors.primary }}
      style={{ backgroundColor: theme.custom.darkGray }}
    />
  );

  return (
    <Column
      flex={1}
      maxWidth={props.width}
      position="relative"
      backgroundColor={theme.colors.background}
      borderWidth={1}
      borderColor={theme.custom.darkGray}
    >
      {/* <PresentersList /> */}

      <View style={{ flex: 1 }}>
        <ParticipantCountView />
        <TabView
          // tabBarPosition="bottom"
          navigationState={{ index, routes }}
          renderScene={renderScene}
          tabBarPosition={props.tabBarPosition ?? "bottom"}
          // lazy
          renderTabBar={renderTabBar}
          swipeEnabled={false}
          onIndexChange={setIndex}
          initialLayout={{ width: layout.width }}
        />
        <BottomView />
      </View>
    </Column>
  );
};

export default SideView;
