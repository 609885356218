import React from "react";
import { useList } from "react-firebase-hooks/database";
import { LayoutRectangle } from "react-native";
import { CanvasPath, ReactSketchCanvasRef } from "react-sketch-canvas";
import { createContext, useContextSelector } from "use-context-selector";
import { FirebaseCollections } from "../../firebase";
import { useMakeClassInstance, usePrevious, useThrottle } from "../hooks";
import DrawingService from "../services/drawing.service";
import { selectChannel, useChannelStore } from "./channel.provider";

interface Context {
  loadCanvas: (ref: ReactSketchCanvasRef, layout: LayoutRectangle) => void;
  undo: () => Promise<void>;
  redo: () => Promise<void>;
  eraseCanvas: () => Promise<void>;

  savePath: (path: CanvasPath) => Promise<void>;
}

const drawingContext = createContext<Context>({} as Context);

const useQuery = (channelId: string) => {
  const dbQuery = React.useMemo(() => {
    return FirebaseCollections.drawing(channelId);
  }, [channelId]);

  return useList(dbQuery);
};

/// Handles all business logic for canvas drawing
const DrawingProvider: React.FC = (props) => {
  const [rect, setRect] = React.useState<LayoutRectangle>();
  const [presenterRect, setPresenterRect] = React.useState<{
    x: number;
    y: number;
  }>(null);
  const redoItems = React.useRef<CanvasPath[]>([]);
  const channel = useChannelStore(selectChannel);

  const drawingService = useMakeClassInstance(DrawingService, channel.id);

  const canvasRef = React.useRef<ReactSketchCanvasRef>();
  const previousRect = usePrevious(rect);

  const [values] = useQuery(channel.id);

  // Need to debounce because of delete path
  const snapshots = useThrottle(values, 50);

  React.useEffect(() => {
    if (!rect || !canvasRef.current) {
      //  "Drawing provider has not been loaded"
      return;
    }

    // if (rect !== previousRect) {
    //   canvasRef.current.clearCanvas();
    // }
    canvasRef.current.clearCanvas();

    if (snapshots && snapshots.length > 0 && rect) {
      const paths = drawingService.fromSnapshot(snapshots);
      const absolutePaths = drawingService.convertToAbsolutePosition(
        paths,
        rect
      );

      canvasRef.current.loadPaths(absolutePaths);
    }
  }, [snapshots, rect, previousRect]);

  const savePath = React.useCallback(
    async (path: CanvasPath) => {
      if (!rect) return;

      if (path.paths?.length < 2) return;
      // Reset redo bucket
      redoItems.current = [];
      const relativePath = drawingService.toFirebase(path, rect);
      await drawingService.savePath(relativePath);
    },
    [rect]
  );

  const eraseCanvas = React.useCallback(async () => {
    canvasRef.current.clearCanvas();
    await drawingService.clearPath();
  }, [snapshots]);

  const undo = React.useCallback(async () => {
    if (snapshots.length > 0) {
      const lastSnapshot = [...snapshots].pop();
      redoItems.current.push(lastSnapshot.val());
      await drawingService.removePath(lastSnapshot.key);
    }
  }, [snapshots]);

  const redo = React.useCallback(async () => {
    if (redoItems.current.length > 0) {
      const lastRedo = redoItems.current.pop();

      await drawingService.savePath(lastRedo);
    }
  }, [rect]);

  const loadCanvas = React.useCallback(
    (ref: ReactSketchCanvasRef, rect: LayoutRectangle) => {
      canvasRef.current = ref;
      setRect(rect);
    },
    []
  );

  return (
    <drawingContext.Provider
      value={{ loadCanvas, savePath, undo, redo, eraseCanvas }}
    >
      {props.children}
    </drawingContext.Provider>
  );
};

function useDrawingProvider<T>(selector: (context: Context) => T) {
  return useContextSelector(drawingContext, selector);
}

export { DrawingProvider, useDrawingProvider };
