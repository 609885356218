import { Ionicons } from "@expo/vector-icons";
import { FirebaseError } from "firebase/app";
import React, { useState } from "react";
import { Caption, TextInput } from "react-native-paper";
import {
  Column,
  FlexSpacer,
  Row,
  Spacer8,
} from "../../helpers/layoutPrimitives";
import { useClassValidator } from "../../helpers/useClassValidator";
import { useSnack } from "../../hooks/useNotifier";
import { GuestFormDto } from "../../modules/auth/guest-form.dto";
import { useUser } from "../../modules/user/user.provider";
import { theme } from "../../theme";
import Button from "../atoms/Button";
import TextField from "../atoms/TextField";

type GuestFormProps = {};

const GuestForm: React.FC<GuestFormProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const signIn = useUser((s) => s.signIn);
  const { notifyError } = useSnack();

  const { fields, msgs, validate, onChange } = useClassValidator(GuestFormDto);

  const handleOnSubmit = async () => {
    const { data } = await validate();

    if (!data) {
      return;
    }

    try {
      setLoading(true);

      await signIn(data);
    } catch (err) {
      if (err instanceof FirebaseError) {
        let errorMessage: string;
        switch (err.code) {
          case "auth/user-not-found":
            errorMessage = "Credentials are incorrect";
            break;

          case "auth/user-disabled":
            errorMessage = "Your account has been disabled";
            break;

          default:
            errorMessage = "An error occurred";
            break;
        }

        notifyError(errorMessage);
      }
    } finally {
      // setLoading(false);
    }
  };

  return (
    <Row>
      <Column maxWidth={250}>
        <Caption>
          You can join as a guest. Your display name is public and will be seen
          by all participants.
        </Caption>
        <FlexSpacer />

        <Row>
          <TextField
            label="Display Name"
            value={fields.displayName}
            error={msgs?.displayName}
            onSubmitEditing={handleOnSubmit}
            disabled={loading}
            onChangeText={(text) => onChange("displayName", text)}
            right={
              <TextInput.Icon
                disabled={true}
                name={() => (
                  <Ionicons
                    name={"person-circle"}
                    size={20}
                    color={theme.colors.placeholder}
                  />
                )}
              />
            }
          />
        </Row>
        <Spacer8 />

        <Button
          loading={loading}
          onPress={handleOnSubmit}
          icon="arrow-forward"
          reverse
        >
          Join as Guest
        </Button>
      </Column>
    </Row>
  );
};

export default GuestForm;
