import { signInAnonymously, updateProfile, User } from "firebase/auth";
import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { createContext, useContextSelector } from "use-context-selector";
import { useFunction } from "../../client/hooks";
import { firebaseAuth } from "../../firebase";
import { GuestFormDto } from "../auth/guest-form.dto";

interface Context {
  user: User;
  loading: boolean;
  error: Error;
  ready: boolean;
  needInteraction: boolean;
  signIn: (dto: GuestFormDto) => Promise<void>;
  interactWithDom: () => void;
}

const userContext = createContext<Context>({} as Context);

const UserProvider: React.FC = (props) => {
  const [user, loading, error] = useAuthState(firebaseAuth, {
    onUserChanged: async (user) => {
      console.log("User changed", user);
    },
  });

  const [hasInteracted, setHasInteracted] = React.useState(false);

  React.useEffect((): void => {
    window.addEventListener("click", interactWithDom);
  }, []);

  const interactWithDom = () => {
    setHasInteracted(true);
    window.removeEventListener("click", interactWithDom);
  };

  const signIn = useFunction(async (dto: GuestFormDto) => {
    const { user } = await signInAnonymously(firebaseAuth);
    await updateProfile(user, { displayName: dto.displayName });
    await firebaseAuth.currentUser.getIdToken(true);
  });

  const values: Context = React.useMemo(
    () => ({
      user,
      loading,
      error,
      ready: user && hasInteracted,
      needInteraction: user && !hasInteracted,
      signIn,
      interactWithDom,
    }),
    [user, loading, error, hasInteracted, interactWithDom]
  );

  return (
    <userContext.Provider value={values}>{props.children}</userContext.Provider>
  );
};

export { UserProvider, useUser };

function useUser<T>(selector: (context: Context) => T) {
  return useContextSelector(userContext, selector);
}
