import { PortalProvider } from "@gorhom/portal";
import { NavigationContainer } from "@react-navigation/native";
import * as SplashScreen from "expo-splash-screen";
import React from "react";
import { Provider as PaperProvider } from "react-native-paper";
import { MessageProvider as PaperMessageProvider } from "react-native-paper-message-context";
import "reflect-metadata";
import { Container } from "./src/helpers/layoutPrimitives";
import { LoadingOverlay } from "./src/modules/shared/components/LoadingOverlay";
import { LoadingProvider } from "./src/modules/shared/loading.provider";
import { UserProvider } from "./src/modules/user/user.provider";
import { AppNavigator } from "./src/navigator/AppNavigator";
import { theme } from "./src/theme";

if (!__DEV__) {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}

export default function App() {
  const [ready, setReady] = React.useState(false);

  const startApp = React.useCallback(async () => {
    await SplashScreen.preventAutoHideAsync();

    // Hide the splash screen as soon as our app is ready.
    // Add any starting scripts here

    setReady(true);
    await SplashScreen.hideAsync();
  }, []);

  React.useEffect(() => {
    startApp();
  }, [startApp]);

  return (
    <PaperProvider theme={theme}>
      <NavigationContainer>
        <PaperMessageProvider>
          <LoadingProvider>
            <UserProvider>
              <PortalProvider>
                <Container flex={1} backgroundColor={theme.colors.background}>
                  <LoadingOverlay />
                  <AppNavigator />
                </Container>
              </PortalProvider>
            </UserProvider>
          </LoadingProvider>
        </PaperMessageProvider>
      </NavigationContainer>
    </PaperProvider>
  );
}
