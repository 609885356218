import { usePing } from "../client/hooks/usePing";
import { useUser } from "../modules/user/user.provider";
import { PrivateNavigator } from "./PrivateNavigator";
import { PublicNavigator } from "./PublicNavigator";

export function AppNavigator() {
  usePing();

  const { loading, ready, error } = useUser((state) => ({
    ready: state.ready,
    loading: state.loading,
    error: state.error,
  }));

  if (loading) {
    return null;
  }

  if (ready) {
    return <PrivateNavigator />;
  }

  return <PublicNavigator />;
}
