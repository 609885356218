import * as React from "react";
import { ActionsProps } from "react-native-gifted-chat";
import { IconButton } from "react-native-paper";
import {
  ChatExtension,
  selectActiveChatExtension,
  useChatActions,
  useChatStore,
} from "../../../client/providers/chat.provider";
import { Row, Spacer8 } from "../../../helpers/layoutPrimitives";
import { theme } from "../../../theme";

type CustomActionsProps = Readonly<ActionsProps>;

const CustomActions: React.FC<CustomActionsProps> = (props) => {
  const { setActiveChatExtension } = useChatActions();
  const activeExtension = useChatStore(selectActiveChatExtension);

  const toggleSelection = React.useCallback(
    (extension: ChatExtension) => {
      if (activeExtension === extension) {
        setActiveChatExtension(ChatExtension.None);
      } else {
        setActiveChatExtension(extension);
      }
    },
    [activeExtension]
  );

  return (
    <Row>
      <Spacer8 />
      <IconButton
        icon="emoticon"
        color={
          activeExtension === ChatExtension.Reactions
            ? theme.colors.primary
            : "grey"
        }
        size={20}
        style={{ marginHorizontal: 0 }}
        onPress={() => toggleSelection(ChatExtension.Reactions)}
      />

      <IconButton
        icon="sticker"
        size={20}
        style={{ marginHorizontal: 0 }}
        color={
          activeExtension === ChatExtension.SuperReactions
            ? theme.colors.primary
            : "grey"
        }
        onPress={() => toggleSelection(ChatExtension.SuperReactions)}
      />
    </Row>
  );
};

export default CustomActions;
