import { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Colors } from "react-native-paper";
import { firebaseAuth } from "../../firebase";
import { Column, Row, Spacer8 } from "../../helpers/layoutPrimitives";
import { useClassValidator } from "../../helpers/useClassValidator";
import { useSnack } from "../../hooks/useNotifier";
import { CredentialsDto } from "../../modules/auth/credentials.dto";
import Button from "../atoms/Button";
import { PasswordField } from "../atoms/PasswordField";
import TextField from "../atoms/TextField";

type SignInFormProps = {};

const SignInForm: React.FC<SignInFormProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const { notifyError } = useSnack();
  const { fields, msgs, validate, onChange } =
    useClassValidator(CredentialsDto);

  const handleOnSubmit = async () => {
    const { data } = await validate();

    try {
      setLoading(true);
      await signInWithEmailAndPassword(firebaseAuth, data.email, data.password);
    } catch (err) {
      if (err instanceof FirebaseError) {
        let errorMessage: string;
        console.error(err);
        switch (err.code) {
          case "auth/user-not-found":
          case "auth/wrong-password":
            errorMessage = "Credentials are incorrect";
            break;

          case "auth/user-disabled":
            errorMessage = "Your account has been disabled";
            break;

          default:
            errorMessage = "An error occurred";
            break;
        }

        notifyError(errorMessage);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row flex={1}>
      <Column>
        <TextField
          label="Email"
          value={fields.email}
          error={msgs?.email}
          disabled={loading}
          onChangeText={(text) => onChange("email", text)}
        />
        <Spacer8 />
        <PasswordField
          label="Password"
          value={fields.password}
          error={msgs?.password}
          disabled={loading}
          onSubmitEditing={handleOnSubmit}
          onChangeText={(text) => onChange("password", text)}
        />
        <Spacer8 />

        <Button
          onPress={handleOnSubmit}
          loading={loading}
          icon="arrow-forward"
          reverse
          color={Colors.black}
        >
          Login
        </Button>
      </Column>
    </Row>
  );
};

export default SignInForm;
