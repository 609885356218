import React from "react";

import ChannelAPI from "../services/channel.service";

export function usePing() {
  const doPing = async () => {
    await ChannelAPI.ping();
  };

  React.useEffect(() => {
    doPing();
  }, []);

  return;
}
