import { Ionicons } from "@expo/vector-icons";
import React, { useState } from "react";
import { DarkTheme, TextInput } from "react-native-paper";
import { TextInputProps } from "react-native-paper/lib/typescript/components/TextInput/TextInput";
import TextField from "./TextField";

interface PasswordInputProps extends Omit<TextInputProps, "theme" | "error"> {
  error?: string | undefined;
}
export const PasswordField: React.FC<PasswordInputProps> = (props) => {
  const [reveal, setReveal] = useState(false);

  return (
    <TextField
      {...props}
      autoCompleteType="password"
      secureTextEntry={!reveal}
      right={
        <TextInput.Icon
          onPress={() => setReveal(!reveal)}
          name={() => (
            <Ionicons
              name={reveal ? "eye-off" : "eye"}
              size={20}
              color={DarkTheme.colors.placeholder}
            />
          )}
        />
      }
    />
  );
};
