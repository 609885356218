import React, { useMemo } from "react";
import { Caption, Colors, Divider } from "react-native-paper";
import {
  selectLiveUsers,
  useChannelStore,
} from "../../client/providers/channel.provider";
import {
  selectLiveParticipants,
  useParticipantStore,
} from "../../client/providers/participants.provider";
import { Column, Row, Spacer8 } from "../../helpers/layoutPrimitives";
import ParticipantAvatar from "../atoms/ParticipantAvatar";

const ParticipantCountView: React.FC = () => {
  const liveUsers = useChannelStore(selectLiveUsers);
  const liveParticipants = useParticipantStore(selectLiveParticipants);

  const topParticipants = useMemo(() => {
    return liveParticipants
      .map((participant) => participant.data())
      .slice(0, 3);
  }, [liveParticipants]);

  const participantLabel = useMemo(() => {
    const label = liveUsers === 1 ? "Participant" : "Participants";
    return `${liveUsers} ${label}`;
  }, [liveUsers]);

  return (
    <Column>
      <Spacer8 />
      <Row alignItems="center">
        <Spacer8 />
        {topParticipants.map((liveParticipant, index) => (
          <ParticipantAvatar
            key={liveParticipant.deviceId}
            style={{
              marginLeft: index === 0 ? 0 : -10,
              shadowColor: Colors.black,
              shadowRadius: 5,
              shadowOpacity: 0.5,
            }}
            displayName={liveParticipant.participant.displayName}
          />
        ))}
        <Spacer8 />
        <Caption>{participantLabel}</Caption>
      </Row>
      <Spacer8 />
      <Divider />
    </Column>
  );
};

export default React.memo(ParticipantCountView);
