import { ReactionBarSelector } from "@charkour/react-reactions";
import {
  DataSnapshot,
  limitToLast,
  onChildAdded,
  onValue,
  orderByChild,
  query,
} from "firebase/database";
import { throttle } from "lodash";
import React from "react";

import { FirebaseCollections } from "../../firebase";

export function useReactionsList(channelId: string) {
  const [reactions, setReactions] = React.useState<DataSnapshot[]>([]);
  const reactionsRef = React.useRef<DataSnapshot[]>([]);
  const hasLoaded = React.useRef(false);

  const colRef = React.useMemo(() => {
    return query(
      FirebaseCollections.broadcasts(channelId, "REACTION"),
      limitToLast(1)
    );
  }, [channelId]);

  const onInitialLoad = React.useCallback((_: DataSnapshot) => {
    setTimeout(() => (hasLoaded.current = true), 500);
  }, []);

  const handleOnChildAdded = React.useCallback(
    throttle((snapshot: DataSnapshot) => {
      if (!hasLoaded.current) return;
      // TODO: Limit size of array here
      // Keep the array small on teh screen
      if (reactionsRef.current.length > 30) {
        return;
      }

      reactionsRef.current.push(snapshot);
      setTimeout(() => {
        reactionsRef.current = reactionsRef.current.filter(function (obj) {
          return obj.ref.key !== snapshot.ref.key;
        });
      }, 3000);
      setReactions([...reactionsRef.current]);
    }, 200),
    []
  );

  React.useEffect(() => {
    // Initial load to clean up queue

    onValue(colRef, onInitialLoad, null, { onlyOnce: true });

    const unsubscribe = onChildAdded(colRef, handleOnChildAdded);
    return () => {
      hasLoaded.current = false;
      reactionsRef.current = [];
      unsubscribe();
    };
  }, [colRef]);

  return reactions;
}
