import { BrowserUtils } from "@bitwild/vxdk";
import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React from "react";
import { useScreenSize } from "../client/hooks";
import {
  selectChannel,
  useChannelActions,
  useChannelStore,
} from "../client/providers/channel.provider";
import { ChatProvider } from "../client/providers/chat.provider";
import { ConductorProvider } from "../client/providers/conductor.provider";
import { ParticipantsProvider } from "../client/providers/participants.provider";
import { VxdkProvider } from "../client/providers/vxdk.provider";
import PasscodeForm from "../components/organisms/PasscodeForm";
import WatchView from "../components/organisms/WatchView";
import { firebaseAuth } from "../firebase";
import "../global.css";
import { useSnack } from "../hooks/useNotifier";
import ConductorListener from "../modules/conductor/components/ConductorListener";
import { useLoadingSelector } from "../modules/shared/loading.provider";
import { PrivateNavigatorParams } from "../navigator/PrivateNavigator";

type NavigationProps = NativeStackNavigationProp<
  PrivateNavigatorParams,
  "WatchScreen"
>;

type RouteProps = RouteProp<PrivateNavigatorParams, "WatchScreen">;

type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

const WatchScreen: React.FC<Props> = ({ navigation, route }) => {
  const setLoading = useLoadingSelector(({ setLoading }) => setLoading);
  const { isSmall, isLandscape } = useScreenSize();

  React.useEffect(() => {
    if (BrowserUtils.isMobile) {
      // If its not ipad and aspect ratio is landscape
      if (isLandscape) {
        navigation.setOptions({ headerShown: false });
      } else {
        navigation.setOptions({ headerShown: true });
      }
    }
  }, [isLandscape]);

  const channel = useChannelStore(selectChannel);
  const { notifyError } = useSnack();

  const [needPasscode, setNeedPasscode] = React.useState(false);

  const actions = useChannelActions();

  const handlePasscode = async (passcode: string) => {
    await handleJoin(passcode);
  };

  const handleJoin = React.useCallback(
    async (passcode?: string) => {
      setLoading(true);
      setNeedPasscode(false);

      try {
        const { canJoin, needPasscode } = await actions.canJoin();

        if (!canJoin) {
          throw Error("Cannot join this channel.");
        }

        const hasPasscode = !needPasscode || (needPasscode && passcode);

        if (hasPasscode) {
          await actions.joinChannel({ passcode });

          setTimeout(() => setLoading(false), 2000);
          return;
        }

        setNeedPasscode(needPasscode);
        setLoading(false);
      } catch (err) {
        notifyError(err);
        setLoading(false);
        firebaseAuth.signOut();
      }
    },
    [actions]
  );

  React.useEffect(() => {
    handleJoin();
  }, []);

  if (needPasscode) {
    return <PasscodeForm onSubmit={handlePasscode} />;
  }

  if (!channel) {
    return null;
  }

  return (
    <ParticipantsProvider channelId={channel.id}>
      <VxdkProvider>
        <ConductorProvider>
          <ChatProvider channelId={channel.id}>
            <ConductorListener />
            <WatchView />
          </ChatProvider>
        </ConductorProvider>
      </VxdkProvider>
    </ParticipantsProvider>
  );
};
export default WatchScreen;
