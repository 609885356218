import { Colors, DarkTheme } from "react-native-paper";
import { Theme } from "react-native-paper/lib/typescript/types";
import { Colors as CustomColors } from "./helpers/colors";

export const theme: CustomTheme = {
  ...DarkTheme,
  roundness: 4,
  colors: {
    ...DarkTheme.colors,
    // primary: CustomColors.indigo9,
    accent: CustomColors.teal11,
    surface: Colors.black,
  },
  custom: {
    darkGray: Colors.grey900,
  },
};

interface CustomTheme extends Theme {
  custom: {
    darkGray: string;
  };
}
