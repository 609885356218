import { selectPermissions, useHMSStore } from "@100mslive/hms-video-react";
import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { Caption, List } from "react-native-paper";
import { Column, Row, Spacer16, Spacer8 } from "../../helpers/layoutPrimitives";
import {
  EnumParticipantReferenceDtoRole,
  ParticipantReferenceDto,
} from "../../sdk";
import { theme } from "../../theme";
import ParticipantAvatar from "../atoms/ParticipantAvatar";
import ModerationMenu from "./ModerationMenu";

type ParticipantListItemProps = {
  participant: ParticipantReferenceDto;
};

const ParticipantListItem: React.FC<ParticipantListItemProps> = (props) => {
  const { participant } = props;

  const permissions = useHMSStore(selectPermissions);

  const renderRoleIcons = () => {
    const speakIcon = (
      <Ionicons name="mic-circle" size={20} color={theme.colors.placeholder} />
    );

    const controlIcon = (
      <Ionicons
        name="game-controller"
        size={20}
        color={theme.colors.placeholder}
      />
    );

    if (participant.role === EnumParticipantReferenceDtoRole.MODERATOR) {
      return (
        <Row>
          {controlIcon}
          <Spacer8 />
          {speakIcon}
        </Row>
      );
    }

    if (participant.role === EnumParticipantReferenceDtoRole.PRESENTER) {
      return <Row>{speakIcon}</Row>;
    }

    return null;
  };

  return (
    <List.Item
      left={() => (
        <Row alignItems="center">
          <Spacer8 />
          <ParticipantAvatar displayName={participant.displayName} />
          <Spacer8 />
        </Row>
      )}
      title={participant.displayName}
      right={() => (
        <Row alignItems="center">
          <Column>{renderRoleIcons()}</Column>

          <Spacer16 />
          <Column>
            <Caption>{participant.role}</Caption>
          </Column>
          {permissions?.changeRole && (
            <ModerationMenu participant={participant} />
          )}
          <Spacer8 />
        </Row>
      )}
    />
  );
};

export default ParticipantListItem;
