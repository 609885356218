import { RouteProp } from "@react-navigation/native";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import React, { Fragment } from "react";
import { Image } from "react-native";
import { Headline, Text, Title, useTheme } from "react-native-paper";
import { useScreenSize } from "../client/hooks";
import BitwildLogo from "../components/atoms/BitwildLogo";
import Button from "../components/atoms/Button";
import GuestForm from "../components/organisms/GuestForm";
import SignInForm from "../components/organisms/SignInForm";
import {
  Column,
  Row,
  Spacer,
  Spacer16,
  Spacer32,
} from "../helpers/layoutPrimitives";
import { useUser } from "../modules/user/user.provider";
import { PublicStackParams } from "../navigator/PublicNavigator";

type NavigationProps = NativeStackNavigationProp<
  PublicStackParams,
  "JoinScreen"
>;

type RouteProps = RouteProp<PublicStackParams, "JoinScreen">;

type Props = {
  navigation: NavigationProps;
  route: RouteProps;
};

export const JoinScreen: React.FC<Props> = (props) => {
  const theme = useTheme();

  const { isSmall } = useScreenSize();

  const { needInteraction, interactWithDom, user } = useUser((state) => ({
    needInteraction: state.needInteraction,
    interactWithDom: state.interactWithDom,
    user: state.user,
  }));

  const renderForm = () => {
    if (needInteraction) {
      return (
        <Row>
          <Column>
            <Title>{`Welcome back ${user.displayName}`}</Title>
            <Spacer16 />
            <Button icon="arrow-forward" reverse onPress={interactWithDom}>
              Continue
            </Button>
          </Column>
        </Row>
      );
    }
    return (
      <Fragment>
        <Row>
          <Text
            style={{
              textAlign: "center",
              fontSize: 18,
              color: theme.colors.placeholder,
              lineHeight: 26,
              maxWidth: 460,
            }}
          >
            You have been invited to join a technology showcase of a new
            interactive video experience.
          </Text>
        </Row>
        <Spacer size={64} />
        <Row>
          <GuestForm />

          {!isSmall && (
            <Fragment>
              <Spacer32 />

              <Column justifyContent="center">
                <Text>OR</Text>
              </Column>

              <Spacer32 />

              <SignInForm />
            </Fragment>
          )}
        </Row>
      </Fragment>
    );
  };

  return (
    <Row
      flex={1}
      backgroundColor={theme.colors.surface}
      position="relative"
      center
    >
      <Image
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          opacity: 0.2,
        }}
        source={{
          // Retro road
          // uri: "https://media.giphy.com/media/tVM0hQmU52iKcLI1Kk/giphy.gif",
          // Swirly blinking lines
          // uri: "https://media.giphy.com/media/j0eCtu80OEzbIft6C6/giphy-downsized.gif",
          // Going through space stripes
          uri: "https://media.giphy.com/media/l3vRnoppYtfEbemBO/giphy.gif",
          //Static
          // uri: "https://media.giphy.com/media/gw3RX8HWJygFr5pC/giphy.gif",
          // uri: "https://media.giphy.com/media/TG8DczcGF3iHV6w05G/giphy.gif",
        }}
      />
      <Column
        flex={1}
        alignItems="center"
        paddingHorizontal={20}
        maxWidth={600}
      >
        <Row>
          <BitwildLogo width={75} height={75} />
        </Row>
        <Spacer32 />
        <Headline>It's Time To Experience</Headline>

        <Text style={{ fontSize: 48, fontWeight: "bold", textAlign: "center" }}>
          Video as a Platform
        </Text>

        <Spacer16 />

        {renderForm()}
      </Column>
    </Row>
  );
};
