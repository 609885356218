import React, { useRef } from "react";
import { Animated } from "react-native";
import { isWeb } from "../../client/helpers/utils";
import { useScreenSize } from "../../client/hooks";
import { useVxdkContext } from "../../client/providers/vxdk.provider";
import PresentersList from "../../components/organisms/PresentersList";
import { Container } from "../../helpers/layoutPrimitives";
import { theme } from "../../theme";
import SuperReactionView from "../conductor/components/SuperReactionView";
import DrawCanvas from "../drawing/components/DrawCanvas";
import VxdkView from "./Vxdk";

let options = {
  // source: "https://videos.bitwildcdn.com/sample-stream/hls/master.m3u8",
  source:
    "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
  showUI: false,
  muted: true,
  autoPlay: true,
  poster: "https://source.unsplash.com/user/leosplash/likes/1200x800",
};

type VideoViewProps = {
  width: number;
  height: number;
};

export const VideoView: React.FC<VideoViewProps> = ({ height, width }) => {
  const ready = useVxdkContext(({ state }) => state.ready);
  const { currentTime, duration } = useVxdkContext(({ state }) => ({
    currentTime: state.currentTime,
    duration: state.duration,
  }));

  // const { updateParticipantMetadata } = useChannelActions();

  const waitingForUser = useVxdkContext(({ state }) => state.waitingForUser);
  const { isSmall } = useScreenSize();

  React.useEffect(() => {}, [currentTime]);

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: !isWeb,
    }).start();
  };

  React.useEffect(() => {
    setTimeout(() => fadeIn(), 1500);
  }, [ready, fadeIn]);

  return (
    <Animated.View
      style={{
        borderRadius: 10,
        position: "relative",
        opacity: fadeAnim,
        justifyContent: "center",
        maxHeight: height,
        width: width,
      }}
    >
      {!waitingForUser && (
        <>
          <Container
            position="absolute"
            zIndex={8}
            bottom={0}
            left={20}
            right={0}
          >
            <SuperReactionView />
          </Container>
          <Container position="absolute" zIndex={7} bottom={0} right={0}>
            <PresentersList />
          </Container>
          <DrawCanvas />
        </>
      )}
      <Container
        shadowRadius={10}
        elevation={10}
        shadowColor={theme.colors.backdrop}
      >
        <VxdkView height={height} width={width} options={options} />
      </Container>
    </Animated.View>
  );
};
