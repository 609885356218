import { StyleProp, ViewStyle } from "react-native";
import { Avatar } from "react-native-paper";
import {
  getDisplayNameInitials,
  getUserNameColor,
} from "../../client/helpers/utils";
import { getContrastColor } from "../../helpers/colors";

type ParticpantAvatarProps = {
  displayName: string;
  style?: StyleProp<ViewStyle>;
};

const ParticipantAvatar: React.FC<ParticpantAvatarProps> = (props) => {
  const name = props.displayName ?? "Guest";
  const initals = getDisplayNameInitials(name);
  const color = getUserNameColor(name);
  const textColor = getContrastColor(color);

  return (
    <Avatar.Text
      size={30}
      label={initals}
      color={textColor}
      style={[{ backgroundColor: color, borderRadius: 15 }, props.style]}
    />
  );
};

export default ParticipantAvatar;
