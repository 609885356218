import * as React from "react";
import { AvatarProps, IMessage } from "react-native-gifted-chat";
import { isSameDay, isSameUser } from "react-native-gifted-chat/lib/utils";
import { Avatar } from "react-native-paper";
import {
  getDisplayNameInitials,
  getUserNameColor,
} from "../../../client/helpers/utils";
import { getContrastColor } from "../../../helpers/colors";
import { Container } from "../../../helpers/layoutPrimitives";

type CustomAvatarProps = Readonly<AvatarProps<IMessage>>;

const CustomAvatar: React.FC<CustomAvatarProps> = (props) => {
  const name = props.currentMessage.user.name;
  const initals = getDisplayNameInitials(name);
  const color = getUserNameColor(name);
  const textColor = getContrastColor(color);

  let extraStyle = {};
  if (
    isSameUser(props.currentMessage, props.previousMessage) &&
    isSameDay(props.currentMessage, props.previousMessage)
  ) {
    // Set the invisible avatar height to 0, but keep the width, padding, etc.
    extraStyle = { height: 0 };
  }

  return (
    <Container marginTop={24}>
      <Avatar.Text
        size={30}
        label={initals}
        color={textColor}
        style={{ backgroundColor: color, ...extraStyle, borderRadius: 5 }}
      />
    </Container>
  );
};

export default CustomAvatar;
