import React, { Fragment } from "react";
import { Button, Subheading, Text } from "react-native-paper";
import { ActionType } from "../../client/dto/actions.dto";
import { useConductor } from "../../client/providers/conductor.provider";
import { Container, Spacer, Spacer8 } from "../../helpers/layoutPrimitives";
import { BottomSheet } from "../organisms/BottomView";

type PollViewProps = {};

const PollView: React.FC<PollViewProps> = (props) => {
  const options = ["Yes", "No", "Maybe"];
  const [selection, setSelection] = React.useState<number>(null);

  const [showPoll, setShowPoll] = React.useState(false);

  const actionData = useConductor((context) => context.actionData);

  React.useEffect(() => {
    if (!actionData) return;

    if (actionData.type === ActionType.POLL) {
      setShowPoll(actionData.data ?? false);
    }
  }, [actionData]);

  const handleSelection = (value: number) => {
    setSelection(value);
  };

  const renderOption = (label: string, index: number) => {
    const hasSelected = selection !== null;
    const isSelected = selection === index;
    return (
      <Fragment key={label}>
        <Spacer8 />
        <Button
          mode={isSelected ? "contained" : "outlined"}
          disabled={hasSelected}
          contentStyle={{ height: 35 }}
          style={{ borderRadius: 20 }}
          onPress={() => handleSelection(index)}
          labelStyle={{
            padding: 0,
            margin: 0,
            width: "100%",
          }}
        >
          <Container
            flex={1}
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text>{label}</Text>
          </Container>
        </Button>
      </Fragment>
    );
  };

  return (
    <BottomSheet show={showPoll} onDismiss={() => setShowPoll(false)}>
      <Container padding={20}>
        <Spacer />
        <Subheading style={{ textAlign: "center", fontWeight: "bold" }}>
          Did you agree with the decision to give a yellow card?
        </Subheading>

        {options.map((value, index) => renderOption(value, index))}

        <Spacer />
      </Container>
    </BottomSheet>
  );
};

export default PollView;
