import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import React from "react";
import { Appbar, Caption, Chip, Menu, Text } from "react-native-paper";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useChannelActions } from "../../client/providers/channel.provider";
import { firebaseAuth } from "../../firebase";
import {
  FlexSpacer,
  Row,
  Spacer16,
  Spacer8,
} from "../../helpers/layoutPrimitives";
import { useLoadingSelector } from "../../modules/shared/loading.provider";
import BitwildLogo from "../atoms/BitwildLogo";
import DropDownMenu from "../atoms/DropDownMenu";

const HeaderBar: React.FC<NativeStackHeaderProps> = () => {
  const actions = useChannelActions();
  const [showMenu, setShowMenu] = React.useState(false);
  const setLoading = useLoadingSelector(({ setLoading }) => setLoading);

  const { top } = useSafeAreaInsets();

  const handleSignOut = async () => {
    try {
      setShowMenu(false);
      setLoading(true);
      await actions.leaveChannel();
      await firebaseAuth.signOut();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Appbar.Header statusBarHeight={top}>
      <Spacer16 />
      <BitwildLogo />
      <Row alignItems="baseline">
        <Spacer8 />
        <Text>VXDK</Text>
        <Spacer8 />
        <Caption>/ TECH PREVIEW</Caption>
      </Row>
      <FlexSpacer />
      <Chip>Preview</Chip>
      <Spacer16 />

      <DropDownMenu
        icon="dots-vertical"
        onDismiss={() => setShowMenu(false)}
        visible={showMenu}
        showMenu={() => setShowMenu(true)}
      >
        <Menu.Item onPress={handleSignOut} title="Leave" />
      </DropDownMenu>
    </Appbar.Header>
  );
};

export default HeaderBar;
