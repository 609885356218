import React from "react";
import { Image, ScrollView } from "react-native";
import { Subheading } from "react-native-paper";
import { Column, Row, Spacer16 } from "../../helpers/layoutPrimitives";
import qrCode from "../../images/mobile-sync-square.png";

const MobileSyncView: React.FC = () => {
  return (
    <ScrollView
      style={{
        flex: 1,
      }}
      contentContainerStyle={{ flexGrow: 1 }}
    >
      <Row flex={1} flexGrow={1}>
        <Spacer16 />
        <Column flex={1} center>
          <Subheading style={{ textAlign: "center" }}>
            Interact and participate from your mobile device.
          </Subheading>

          <Spacer16 />
          <Image source={qrCode} style={{ width: 175, height: 175 }} />
          <Spacer16 />
        </Column>
        <Spacer16 />
      </Row>
    </ScrollView>
  );
};

export default MobileSyncView;
