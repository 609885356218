import { HMSRoomProvider } from "@100mslive/hms-video-react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { ChannelProvider } from "../client/providers/channel.provider";
import HeaderBar from "../components/molecules/HeaderBar";
import WatchScreen from "../screens/WatchScreen";
import { theme } from "../theme";

export type PrivateNavigatorParams = {
  WatchScreen: null;
};

const Stack = createNativeStackNavigator<PrivateNavigatorParams>();

export function PrivateNavigator() {
  return (
    <HMSRoomProvider>
      <ChannelProvider channelId="test_channel">
        <Stack.Navigator
          initialRouteName="WatchScreen"
          screenOptions={{
            contentStyle: { backgroundColor: theme.colors.background },
            title: "Watch Screen",
            header: (props) => <HeaderBar {...props} />,
          }}
        >
          <Stack.Screen name="WatchScreen" component={WatchScreen} />
        </Stack.Navigator>
      </ChannelProvider>
    </HMSRoomProvider>
  );
}
