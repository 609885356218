import { Timestamp } from "firebase/firestore";
import { AnimatePresence } from "moti";
import React from "react";
import { GiftedChat, IMessage } from "react-native-gifted-chat";
import {
  ChatExtension,
  selectActiveChatExtension,
  selectMessages,
  useChatActions,
  useChatStore,
} from "../../../client/providers/chat.provider";
import { useVxdkController } from "../../../client/providers/vxdk.provider";
import ReactionSelector from "../../../components/molecules/ReactionSelector";
import GiphyView from "../../../components/organisms/GiphyView";
import { Container } from "../../../helpers/layoutPrimitives";
import { EnumSendMessageDtoType } from "../../../sdk";
import { useUser } from "../../user/user.provider";
import CustomActions from "./Actions";
import CustomAvatar from "./Avatar";
import { ChatComposer } from "./ChatComposer";
import CustomInputToolBar from "./InputToolBar";
import CustomMessage from "./Message";
import ScrollToBottomButton from "./ScrollToBottomButton";
import CustomSendButton from "./SendButton";

function ChatView() {
  const user = useUser(({ user }) => user);
  const messages = useChatStore(selectMessages);
  const activeExtension = useChatStore(selectActiveChatExtension);

  const { sendMessage } = useChatActions();

  const controller = useVxdkController();

  const [chatMessages, setChatMessages] = React.useState<IMessage[]>([]);

  React.useEffect(() => {
    if (messages) {
      setChatMessages(
        messages.map((message) => {
          return {
            _id: message.id,
            text: message.data().content,
            createdAt: (
              message.data().createdAt as unknown as Timestamp
            ).toDate(),
            sent: true,

            user: {
              _id: message.data().sender.userId,
              name: message.data().sender.displayName ?? "Guest",
              avatar: `https://placeimg.com/140/140/any?${
                message.data().sender.userId
              }`,
            },
          } as IMessage;
        })
      );
    }
  }, [messages]);

  const onSend = React.useCallback(
    async (messages: IMessage[] = []) => {
      setChatMessages((previousMessages) =>
        GiftedChat.append(previousMessages, messages)
      );

      await sendMessage({
        content: messages[0].text,
        type: EnumSendMessageDtoType.Text,
        mediaMetadata: {
          timestamp: controller.getCurrentTime(),
        },
      });
    },
    [sendMessage, controller]
  );

  return (
    <Container flex={1}>
      <GiftedChat
        messages={chatMessages}
        onSend={(messages) => onSend(messages)}
        showUserAvatar={false}
        scrollToBottom={true}
        placeholder="Say something..."
        renderComposer={ChatComposer}
        renderAvatar={CustomAvatar}
        renderMessage={CustomMessage}
        renderSend={CustomSendButton}
        wrapInSafeArea={false}
        // renderBubble={CustomBubble}
        renderInputToolbar={(props) => <CustomInputToolBar {...props} />}
        renderUsernameOnMessage={true}
        scrollToBottomComponent={ScrollToBottomButton}
        renderActions={(props) => <CustomActions {...props} />}
        // textInputProps={{ multiline: false }}\
        //TODO: Implement mentions

        parsePatterns={(linkStyle) => [
          {
            pattern: /@(\w+)/,
            style: { ...linkStyle },
            onPress: () => alert(),
          },
        ]}
        maxInputLength={140}
        user={{
          _id: user.uid,
          name: user.displayName,
          avatar: user.photoURL,
        }}
      />
      <Container maxHeight="50%">
        <AnimatePresence>
          {activeExtension === ChatExtension.SuperReactions && <GiphyView />}

          {activeExtension === ChatExtension.Reactions && <ReactionSelector />}
        </AnimatePresence>
      </Container>
    </Container>
  );
}

export default ChatView;
