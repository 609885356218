import * as React from "react";
import { View } from "react-native";
import { IconButton, Menu } from "react-native-paper";
import { IconSource } from "react-native-paper/lib/typescript/components/Icon";
type DropDownMenuProps = {
  icon: IconSource;
  visible: boolean;
  onDismiss: () => void;
  showMenu: () => void;
};
const DropDownMenu: React.FC<DropDownMenuProps> = (props) => {
  const { visible, icon, children, onDismiss, showMenu } = props;

  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <Menu
        contentStyle={{ marginTop: 50 }}
        visible={visible}
        onDismiss={onDismiss}
        anchor={<IconButton icon={icon} size={24} onPress={showMenu} />}
      >
        {children}
      </Menu>
    </View>
  );
};

export default DropDownMenu;
