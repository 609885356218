import React from "react";
import { InputToolbar, InputToolbarProps } from "react-native-gifted-chat";
import { Caption, IconButton } from "react-native-paper";
import { useFunction } from "../../../client/hooks";
import {
  ChatExtension,
  selectActiveChatExtension,
  useChatActions,
  useChatStore,
} from "../../../client/providers/chat.provider";
import { FlexSpacer, Row } from "../../../helpers/layoutPrimitives";
import { theme } from "../../../theme";
import CustomActions from "./Actions";

type CustomInputToolBarProps = Readonly<InputToolbarProps>;

const CustomInputToolBar: React.FC<CustomInputToolBarProps> = (props) => {
  const activeExtension = useChatStore(selectActiveChatExtension);
  const { setActiveChatExtension } = useChatActions();

  const extensionLabel = useFunction((extension: ChatExtension) => {
    if (extension === ChatExtension.Reactions) {
      return "Reactions";
    }

    if (extension === ChatExtension.SuperReactions) {
      return "Super Reactions";
    }
  });

  if (activeExtension === ChatExtension.None) {
    return (
      <InputToolbar
        {...props}
        containerStyle={{
          borderWidth: 1,
          borderColor: "#333",
          backgroundColor: theme.colors.background,
          borderRadius: 0,
        }}
      />
    );
  } else {
    return (
      <Row
        alignItems="center"
        borderTopWidth={1}
        borderColor={theme.colors.primary}
      >
        <CustomActions />
        <FlexSpacer />
        <Caption>{extensionLabel(activeExtension)}</Caption>
        <FlexSpacer />
        <IconButton
          icon="close"
          onPress={() => setActiveChatExtension(ChatExtension.None)}
        />
      </Row>
    );
  }
};

export default CustomInputToolBar;
