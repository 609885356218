import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
  useBottomSheetDynamicSnapPoints,
  useBottomSheetModal,
} from "@gorhom/bottom-sheet";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { StyleSheet } from "react-native";
import { Button } from "react-native-paper";
import { Column, Spacer16 } from "../../helpers/layoutPrimitives";
import { theme } from "../../theme";
import PollView from "../molecules/PollView";

type BottomSheetProps = {
  show: boolean;
  onDismiss: () => void;
};

export const BottomSheet: React.FC<BottomSheetProps> = (props) => {
  const { show, onDismiss, children } = props;

  const bottomSheetRef = useRef<BottomSheetModal>(null);

  const snapPoints = useMemo(() => ["1%", "CONTENT_HEIGHT"], []);

  const {
    animatedHandleHeight,
    animatedSnapPoints,
    animatedContentHeight,
    handleContentLayout,
  } = useBottomSheetDynamicSnapPoints(snapPoints);

  // callbacks
  const handleSheetChanges = React.useCallback((index: number) => {
    if (index === 0) {
      bottomSheetRef.current.dismiss();
    }
  }, []);

  useEffect(() => {
    if (show) {
      bottomSheetRef.current.present();
      bottomSheetRef.current.snapToIndex(1);
    } else {
      bottomSheetRef.current.close();
    }
  }, [show]);

  const renderBackdrop = useCallback(
    (props) => (
      <BottomSheetBackdrop
        {...props}
        disappearsOnIndex={0}
        appearsOnIndex={1}
        opacity={0.8}
        pressBehavior={() => {}}
      />
    ),
    []
  );

  const renderFooter = (props) => {
    const { dismissAll } = useBottomSheetModal();
    return (
      <Column>
        <Button onPress={dismissAll}>Dismiss</Button>
        <Spacer16 />
      </Column>
    );
  };

  // renders
  return (
    <BottomSheetModal
      ref={bottomSheetRef}
      index={1}
      backgroundStyle={{
        backgroundColor: theme.colors.background,
        borderColor: theme.colors.primary,
        borderWidth: 1,
      }}
      handleHeight={animatedHandleHeight}
      contentHeight={animatedContentHeight}
      snapPoints={animatedSnapPoints}
      onChange={handleSheetChanges}
      backdropComponent={renderBackdrop}
      handleIndicatorStyle={{ backgroundColor: theme.custom.darkGray }}
      bottomInset={100}
      // topInset={40}
      onDismiss={onDismiss}
      footerComponent={renderFooter}
      detached={true}
      style={styles.sheetContainer}
    >
      <BottomSheetScrollView onLayout={handleContentLayout}>
        {children}
      </BottomSheetScrollView>
    </BottomSheetModal>
  );
};

const BottomView: React.FC = () => {
  return (
    <BottomSheetModalProvider>
      <PollView />
    </BottomSheetModalProvider>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 24,
    backgroundColor: "grey",
  },
  sheetContainer: {
    // add horizontal space
    // padding: 20,
    marginHorizontal: 24,
  },
});

export default BottomView;
