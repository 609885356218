import React, { useState } from "react";
import { TextInput } from "react-native-paper";
import { TextInputProps } from "react-native-paper/lib/typescript/components/TextInput/TextInput";
import { Column } from "../../helpers/layoutPrimitives";
import ErrorText from "./ErrorText";

interface Props extends Omit<TextInputProps, "theme" | "error"> {
  error?: string | undefined;
}

const TextField: React.FC<Props> = (props) => {
  const { error, value, onChangeText } = props;
  const [isFocused, setIsFocused] = useState(false);
  const hasError = !!error;

  return (
    <Column flex={1}>
      <TextInput
        mode="outlined"
        {...props}
        value={value ?? ""}
        // style={{ backgroundColor: Colors.grey900 }}
        selectionColor="red"
        onChangeText={(text) => {
          onChangeText?.call(onChangeText, text);
        }}
        error={hasError}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      />

      <ErrorText visible={hasError} message={error} />
    </Column>
  );
};

export default TextField;
