import Vxdk, {
  CONTEXT_EVENT,
  Controller,
  Disposable,
  Options,
  StateDto,
  VXDKDefaultState,
} from "@bitwild/vxdk";
import React from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { ConductorPlugin } from "../../conductor/conductor";
import { useFunction } from "../hooks";

type LoadFn = (
  ref: React.MutableRefObject<any>,
  options: Partial<Options>
) => Promise<void>;

interface Context {
  load: LoadFn;
  controller: Controller;
  unload: () => void;
  state: StateDto;
}

const vxdkContext = createContext<Context>({} as Context);

const VxdkProvider: React.FC = (props) => {
  const [controller, setController] = React.useState<Controller>(null);
  const vxdkRef = React.useRef<Controller>(null);
  const [state, setState] = React.useState<StateDto>(VXDKDefaultState);

  const subscriptionsRef = React.useRef<Disposable[]>([]);

  React.useEffect(() => {
    console.log("Vxdk provider mounted");
    return () => vxdkRef.current?.destroy();
  }, []);

  const load: LoadFn = useFunction(async (containerRef, options) => {
    if (containerRef.current) {
      Vxdk.registerPlugin(ConductorPlugin);
      const vxdkController = Vxdk.init(containerRef.current, options);

      // Only set controller after is ready
      vxdkController.once(CONTEXT_EVENT.READY, () => {
        setController(vxdkController);
      });

      const stateSub = vxdkController.on(CONTEXT_EVENT.STATE_CHANGED, () => {
        setState(vxdkController.getState());
      });
      subscriptionsRef.current.push(stateSub);
    }
  });

  const unload = React.useCallback(() => {
    controller?.destroy();
    setController(null);
    subscriptionsRef.current.forEach((sub) => sub.dispose());
    subscriptionsRef.current = [];
  }, [controller]);

  return (
    <vxdkContext.Provider
      value={{
        load,
        unload,
        controller,
        state,
      }}
    >
      {props.children}
    </vxdkContext.Provider>
  );
};

function useVxdkContext<T>(selector: (context: Context) => T) {
  return useContextSelector(vxdkContext, selector);
}

// function useVxdkStore<T>(selector: (state: StateDto) => T) {
//   return useContextSelector(vxdkContext, (context) => {
//     return selector(context.state);
//   });
// }

function useVxdkController() {
  return useVxdkContext((context) => context.controller);
}

export { VxdkProvider, useVxdkContext, useVxdkController };
