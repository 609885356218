import {
  HMSPeer,
  selectDominantSpeaker,
  selectIsConnectedToRoom,
  selectPeersByRole,
  useHMSStore,
} from "@100mslive/hms-video-react";
import { AnimatePresence } from "moti";
import React, { Fragment } from "react";
import { Column, Row, Spacer8 } from "../../helpers/layoutPrimitives";
import { AnimateDisplaySize } from "../animation/AnimateDisplay";
import PresenterTile from "../molecules/PresenterTile";

const PresentersList: React.FC = () => {
  const isConnected = useHMSStore(selectIsConnectedToRoom);

  if (!isConnected) {
    return null;
  }

  return (
    <AnimatePresence>
      {isConnected && (
        <AnimateDisplaySize duration={500}>
          <PresentersView />
        </AnimateDisplaySize>
      )}
    </AnimatePresence>
  );
};

export default PresentersList;

const PresentersView: React.FC = () => {
  const moderators = useHMSStore(selectPeersByRole("moderator"));
  const presenters = useHMSStore(selectPeersByRole("presenter"));
  const prominentSpeaker = useHMSStore(selectDominantSpeaker);

  const speakers = React.useMemo(() => {
    return [...moderators, ...presenters] as HMSPeer[];
  }, [moderators, presenters]);

  if (!speakers.length) {
    return null;
  }

  const selectSpeaker = prominentSpeaker;

  // if (selectSpeaker) {
  //   return (
  //     <Container padding={10} alignItems="center" key={selectSpeaker.id}>
  //       <PresenterTile key={selectSpeaker.id} speaker={selectSpeaker} />
  //     </Container>
  //   );
  // }

  // return null;

  return (
    <Column>
      <Row padding={10} alignItems="center">
        {speakers.map((speaker) => {
          return (
            <Fragment key={speaker.id}>
              <PresenterTile key={speaker.id} speaker={speaker} />
              <Spacer8 />
            </Fragment>
          );
        })}
      </Row>
    </Column>
  );
};
