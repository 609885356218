import { QueryDocumentSnapshot } from "firebase/firestore";
import React from "react";
import { FlatList, ListRenderItem } from "react-native";
import { Divider } from "react-native-paper";
import {
  selectLiveParticipants,
  useParticipantStore,
} from "../../client/providers/participants.provider";
import { Padding } from "../../helpers/layoutPrimitives";
import { Presence } from "../../sdk";
import { AnimateDisplay } from "../animation/AnimateDisplay";
import ParticipantListItem from "../molecules/ParticipantListItem";

const ParticipantsList: React.FC = () => {
  const participants = useParticipantStore(selectLiveParticipants);

  const renderSeparator = () => {
    return <Divider />;
  };

  const renderItem: ListRenderItem<QueryDocumentSnapshot<Presence>> = ({
    item,
  }) => {
    const liveParticipant = item.data();

    return (
      <AnimateDisplay>
        <Padding size={10}>
          <ParticipantListItem participant={liveParticipant.participant} />
        </Padding>
      </AnimateDisplay>
    );
  };

  return (
    <FlatList
      data={participants}
      renderItem={renderItem}
      // contentContainerStyle={{ padding: 20 }}
      keyExtractor={(item) => item.id}
      ItemSeparatorComponent={renderSeparator}
    />
  );
};

export default React.memo(ParticipantsList);
