import { QueryDocumentSnapshot } from "firebase/firestore";
import { AnimatePresence, MotiView } from "moti";
import React, { useCallback, useState } from "react";
import { Image, ScrollView } from "react-native";
import { Colors, Paragraph, Surface } from "react-native-paper";
import { useSuperReactions } from "../../../client/providers/chat.provider";
import { Column, Row } from "../../../helpers/layoutPrimitives";
import { MessageDto } from "../../../sdk";

const SuperReactionView: React.FC = () => {
  const { superReactions, removeSuperReaction } = useSuperReactions();
  const [refMap, setRefMap] = useState({});

  const renderItem = useCallback(
    (item: QueryDocumentSnapshot<MessageDto>) => {
      return (
        <AnimatePresence key={item.id}>
          {!refMap[item.id] && (
            <MotiView
              from={{
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{
                opacity: 0,
                scale: 0,
              }}
              onDidAnimate={() => {
                setTimeout(() => {
                  refMap[item.id] = true;
                  setRefMap({ ...refMap });
                  setTimeout(() => {
                    removeSuperReaction(item.id);
                  }, 500);
                }, 10000);
              }}
            >
              <Row>
                <Column justifyContent="flex-end">
                  <Image
                    source={{
                      uri: item?.data().metadata?.imageUrl,
                    }}
                    resizeMode="contain"
                    style={{
                      height: 125,
                      width: 125,
                      marginHorizontal: 16,
                    }}
                  />
                </Column>

                <Column justifyContent="center">
                  <Row alignItems="center">
                    <Surface
                      style={{
                        elevation: 8,
                        borderRadius: 10,
                        padding: 16,
                        backgroundColor: Colors.grey100,
                      }}
                    >
                      <Paragraph
                        style={{
                          fontWeight: "bold",
                          color: Colors.black,
                          maxWidth: 250,
                        }}
                      >
                        {item.data().content}
                      </Paragraph>
                    </Surface>
                  </Row>
                </Column>
              </Row>
            </MotiView>
          )}
        </AnimatePresence>
      );
    },
    [refMap]
  );

  return (
    <ScrollView
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      //   scrollEnabled={false}
    >
      {superReactions.map((reaction) => renderItem(reaction))}
    </ScrollView>
  );
};

export default SuperReactionView;
