import { Platform } from "react-native";
import {
  Composer,
  ComposerProps,
  IMessage,
  SendProps,
} from "react-native-gifted-chat";
import { theme } from "../../../theme";

// export const ChatComposer = (
//   props: ComposerProps & {
//     // GiftedChat passes its props to all of its `render*()`
//     onSend: SendProps<IMessage>["onSend"];
//     text: SendProps<IMessage>["text"];
//   }
// ) => (
//   <TextField {...props} value={props.text} onChangeText={props.onTextChanged} />
// );

export const ChatComposer = (
  props: ComposerProps & {
    // GiftedChat passes its props to all of its `render*()`
    onSend: SendProps<IMessage>["onSend"];
    text: SendProps<IMessage>["text"];
  }
) => (
  <Composer
    {...props}
    textInputStyle={{
      color: theme.colors.text,
    }}
    textInputProps={{
      ...props.textInputProps,

      // for enabling the Return key to send a message only on web
      blurOnSubmit: Platform.OS === "web",
      onSubmitEditing:
        Platform.OS === "web"
          ? () => {
              if (props.text && props.onSend) {
                props.onSend({ text: props.text.trim() }, true);
              }
            }
          : undefined,
    }}
  />
);
