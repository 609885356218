import {
  selectIsLocalAudioEnabled,
  selectIsLocalVideoDisplayEnabled,
  useHMSActions,
  useHMSStore,
} from "@100mslive/hms-video-react";
import React from "react";
import { IconButton } from "react-native-paper";
import { Row } from "../../../helpers/layoutPrimitives";

const SpeakerControls: React.FC = () => {
  const hmsActions = useHMSActions();
  const isLocalAudioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const isLocalVideoEnabled = useHMSStore(selectIsLocalVideoDisplayEnabled);

  return (
    <Row>
      <IconButton
        icon={isLocalAudioEnabled ? "microphone" : "microphone-off"}
        onPress={() => {
          hmsActions.setLocalAudioEnabled(!isLocalAudioEnabled);
        }}
      />
      <IconButton
        icon={isLocalVideoEnabled ? "video" : "video-off"}
        onPress={() => {
          hmsActions.setLocalVideoEnabled(!isLocalVideoEnabled);
        }}
      />
    </Row>
  );
};

export default SpeakerControls;
