import {
  DatabaseReference,
  DataSnapshot,
  get,
  push,
  remove,
  set,
} from "firebase/database";

import { firebaseAuth, FirebaseCollections } from "../../firebase";
import { CanvasPath } from "react-sketch-canvas";
import { LayoutRectangle } from "react-native";

import { getDisplayNameInitials } from "../helpers/utils";

class DrawingService {
  public constructor(private channelId: string) {}

  private getWriteRef(): DatabaseReference {
    return FirebaseCollections.drawing(this.channelId);
  }

  private getWriteRefId(id: string): DatabaseReference {
    return FirebaseCollections.drawingPath(this.channelId, id);
  }

  public async savePath(path: CanvasPath) {
    const userId = firebaseAuth.currentUser?.uid;
    const dbRef = this.getWriteRefId(`${path.startTimestamp}`);

    // TODO: Workaround to not override by different users
    const item = await get(dbRef);
    // Do not allow to write if document exists and its not the userId
    if (item.exists() && item.val().userId !== userId) {
      return;
    }
    await set(dbRef, {
      ...path,
      userId,
    });
  }

  public async removePath(pathId: string) {
    await remove(this.getWriteRefId(pathId));
  }

  public async clearPath() {
    await remove(this.getWriteRef());
  }

  public toFirebase(path: CanvasPath, rect: LayoutRectangle): CanvasPath {
    const relativePaths = path.paths.map((point) => ({
      x: point.x / rect.width,
      y: point.y / rect.height,
    }));

    return {
      ...path,
      paths: relativePaths,
    };
  }

  public fromSnapshot(snapshots: DataSnapshot[]): CanvasPath[] {
    if (snapshots && snapshots.length > 0) {
      return snapshots.map((snapshot) => snapshot.val());
    }

    return [];
  }

  public convertToAbsolutePosition(paths: CanvasPath[], rect: LayoutRectangle) {
    return paths.map((path) => {
      return {
        ...path,
        paths: path.paths.map((point) => ({
          x: point.x * rect.width,
          y: point.y * rect.height,
        })),
      };
    });
  }
}

export default DrawingService;
