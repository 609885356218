import {
  HMSPeer,
  selectCameraStreamByPeerID,
  selectDominantSpeaker,
  selectIsPeerAudioEnabled,
  selectPeerAudioByID,
  useHMSActions,
  useHMSStore,
} from "@100mslive/hms-video-react";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import React, { Fragment, useEffect, useRef } from "react";
import { Text } from "react-native-paper";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import {
  getDisplayNameInitials,
  getUserNameColor,
} from "../../client/helpers/utils";
import { useScreenSize } from "../../client/hooks";
import { getContrastColor } from "../../helpers/colors";
import {
  Column,
  Container,
  FlexSpacer,
  Row,
} from "../../helpers/layoutPrimitives";
import { theme } from "../../theme";
import { AnimateDisplay } from "../animation/AnimateDisplay";

type SpeakerTileProps = {
  speaker: HMSPeer;
};

const PresenterTile: React.FC<SpeakerTileProps> = ({ speaker }) => {
  return (
    <Fragment key={speaker.id}>
      <AnimateDisplay>
        <Column alignItems="center">
          <PresenterAvatar speaker={speaker} />
        </Column>
      </AnimateDisplay>
    </Fragment>
  );
};

export default PresenterTile;

type SpeakerAvatarProps = {
  speaker: HMSPeer;
};

const config = {
  duration: 500,
};

const PresenterAvatar: React.FC<SpeakerAvatarProps> = ({ speaker }) => {
  const { name } = speaker;

  const initials = getDisplayNameInitials(name);
  const color = getUserNameColor(name);
  const textColor = getContrastColor(color);
  const shadowColor = getContrastColor(color, 5);
  const level = useHMSStore(selectPeerAudioByID(speaker.id)) || 0;
  const { isSmall } = useScreenSize();

  const predominantSpeaker = useHMSStore(selectDominantSpeaker);

  const shadowRadius = useSharedValue(0);

  const videoRef = useRef(null);
  const hmsActions = useHMSActions();

  const videoTrack = useHMSStore(selectCameraStreamByPeerID(speaker.id));

  const tileSize = isSmall ? 75 : 100;

  useEffect(() => {
    if (videoRef.current && videoTrack) {
      if (videoTrack.enabled) {
        hmsActions.attachVideo(videoTrack.id, videoRef.current);
      } else {
        hmsActions.detachVideo(videoTrack.id, videoRef.current);
      }
    }
  }, [videoTrack, hmsActions]);

  const audioEnabled = useHMSStore(selectIsPeerAudioEnabled(speaker.id));

  const setShadow = (value: number) => {
    const max = Math.max(0, value / 10);

    const rangedValue = Math.min(max, 10);
    shadowRadius.value = rangedValue;
  };

  React.useEffect((): void => {
    setShadow(level);
  }, [level]);

  const shadowStyle = useAnimatedStyle(() => {
    return {
      shadowRadius: withTiming(shadowRadius.value, config),
    };
  }, [level]);

  const renderAvatar = () => {
    if (videoTrack?.enabled) {
      return <video ref={videoRef} autoPlay muted playsInline />;
    }
    return (
      <Container backgroundColor={color} flex={1} center>
        <Text style={{ color: textColor, fontWeight: "bold", fontSize: 32 }}>
          {initials}
        </Text>
      </Container>
    );
  };

  return (
    <Animated.View
      style={[
        {
          shadowColor: theme.colors.background,
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.5,
          shadowRadius: 6,
          borderRadius: 10,
          position: "relative",
          width: tileSize,
          height: tileSize,
          overflow: "hidden",
          backgroundColor: videoTrack?.enabled ? "black" : "transparent",
        },
      ]}
    >
      {renderAvatar()}

      {!isSmall && (
        <Row
          position="absolute"
          left={0}
          bottom={0}
          right={0}
          padding={5}
          zIndex={1}
        >
          <Text>{speaker.name}</Text>
          <FlexSpacer />
          <Container backgroundColor={"white"} borderRadius={20} padding={2}>
            <MaterialCommunityIcons
              name={audioEnabled ? "microphone" : "microphone-off"}
              size={15}
              color={color}
            />
          </Container>
          <LinearGradient
            // Background Linear Gradient
            colors={["transparent", "rgba(0, 0, 0, 0.5)"]}
            style={{
              position: "absolute",
              bottom: 0,
              top: 0,
              left: 0,
              right: 0,
              zIndex: -1,
            }}
          ></LinearGradient>
        </Row>
      )}
    </Animated.View>
  );
};
