/* eslint-disable no-underscore-dangle, no-use-before-define */

import dayjs from "dayjs";
import * as Localization from "expo-localization";
import React from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { BubbleProps, IMessage, utils } from "react-native-gifted-chat";
import { Caption, Colors, Paragraph, Text } from "react-native-paper";
import { getContrast } from "../../../helpers/colors";
import {
  Column,
  Container,
  Row,
  Spacer8,
} from "../../../helpers/layoutPrimitives";
import { theme } from "../../../theme";

const { isSameUser, isSameDay } = utils;
export const TIME_FORMAT = "LT";

const Bubble: React.FC<BubbleProps<IMessage>> = (props) => {
  const self = props.position === "right";
  function renderMessageText() {
    if (props.currentMessage.text) {
      if (props.renderMessageText) {
        return props.renderMessageText(props);
      }

      const bgColor = self ? theme.colors.primary : Colors.grey900;
      const textColor = getContrast(bgColor);

      return (
        <Column alignItems="flex-start">
          <Container
            borderRadius={10}
            paddingHorizontal={10}
            paddingVertical={5}
            marginBottom={5}
            backgroundColor={bgColor}
          >
            <Paragraph
              style={{ color: textColor, fontSize: 12, lineHeight: 18 }}
            >
              {props.currentMessage.text}
            </Paragraph>
          </Container>
        </Column>
      );
    }
    return null;
  }

  function renderQuickReplies() {
    if (props.currentMessage.quickReplies) {
      if (props.renderQuickReplies) {
        return props.renderQuickReplies(props);
      }
      return null;
    }
    return null;
  }

  function renderUsername() {
    const username = props.currentMessage.user.name;
    if (username) {
      if (props.renderUsername) {
        return props.renderUsername();
      }
      return <Text>{username}</Text>;
    }
    return null;
  }

  function renderTime() {
    if (props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, ...timeProps } = props;
      if (props.renderTime) {
        return props.renderTime(props);
      }

      return (
        <Caption>
          {dayjs(props.currentMessage.createdAt)
            .locale(Localization.locale)
            .format(TIME_FORMAT)}
        </Caption>
      );
    }
    return null;
  }

  function renderCustomView() {
    if (props.renderCustomView) {
      return props.renderCustomView(props);
    }
    return null;
  }

  const isSameThread =
    isSameUser(props.currentMessage, props.previousMessage) &&
    isSameDay(props.currentMessage, props.previousMessage);

  const marginTop = Platform.OS === "android" ? -2 : 0;

  const messageHeader = isSameThread ? null : (
    <Row marginTop={marginTop} alignItems="baseline">
      {renderUsername()}
      <Spacer8 />
      {renderTime()}
    </Row>
  );

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onLongPress={props.onLongPress}
        {...props.touchableProps}
      >
        <View style={styles.wrapper}>
          <View>
            {renderCustomView()}
            {messageHeader}
            {renderMessageText()}
            {renderQuickReplies()}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default Bubble;

// Note: Everything is forced to be "left" positioned with this component.
// The "right" position is only used in the default Bubble.
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "flex-start",
  },
  wrapper: {
    marginRight: 60,
    minHeight: 20,
    justifyContent: "flex-end",
  },
});
