import {
  onSnapshot,
  orderBy,
  query,
  QueryDocumentSnapshot,
  Unsubscribe,
  where,
} from "firebase/firestore";
import React, { useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { FirebaseCollections } from "../../firebase";
import { Presence } from "../../sdk";
import { SelectContextFn } from "../../types";
import { useFunction, useOnMount } from "../hooks";
import { OnlineStatus, ParticipantRole } from "../types/enums";

interface Context {
  presenters: QueryDocumentSnapshot<Presence>[];
  participants: QueryDocumentSnapshot<Presence>[];
  error: any;
  loading: boolean;
}

const participantContext = createContext<Context>({} as Context);

type ParticipantsProviderProps = {
  channelId: string;
};

const ParticipantsProvider: React.FC<ParticipantsProviderProps> = (props) => {
  const { children, channelId } = props;

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [presenters, setPresenters] = useState<
    QueryDocumentSnapshot<Presence>[]
  >([]);
  const [participants, setParticipants] = useState<
    QueryDocumentSnapshot<Presence>[]
  >([]);

  const subscriptionsRef = useRef<Unsubscribe[]>([]);

  useOnMount(() => {
    connect();
    console.log("Conductor mounted");
    return () => {
      disconnect();
      console.log("Conductor unmounted");
    };
  });

  const connect = useFunction(() => {
    const queryPresenters = query(
      FirebaseCollections.presenceList(channelId),
      where("type", "in", [
        ParticipantRole.MODERATOR,
        ParticipantRole.PRESENTER,
      ]),
      where("status", "==", OnlineStatus.ONLINE)
    );

    const queryParticipants = query(
      FirebaseCollections.presenceList(channelId),
      where("status", "==", OnlineStatus.ONLINE),
      orderBy("participant.role")
    );

    const presenterSub = onSnapshot(queryPresenters, (snapshot) => {
      const presenters = snapshot.docs ?? [];
      setPresenters(presenters as QueryDocumentSnapshot<Presence>[]);
    });

    const participantsSub = onSnapshot(queryParticipants, (snapshot) => {
      const participants = snapshot.docs ?? [];

      setParticipants(participants as QueryDocumentSnapshot<Presence>[]);
    });

    subscriptionsRef.current.push(presenterSub, participantsSub);
  });

  const disconnect = useFunction(() => {
    subscriptionsRef.current.forEach((sub) => sub());
    subscriptionsRef.current = [];

    setPresenters([]);
    setParticipants([]);
  });

  return (
    <participantContext.Provider
      value={{
        presenters,
        participants,
        error,
        loading,
      }}
    >
      {children}
    </participantContext.Provider>
  );
};

export {
  ParticipantsProvider,
  useParticipantStore,
  selectLivePresenters,
  selectLiveParticipants,
};

function useParticipantStore<T>(selector: SelectContextFn<T>) {
  return useContextSelector(participantContext, selector);
}

function selectLivePresenters(selector: Context) {
  return selector.presenters;
}

function selectLiveParticipants(selector: Context) {
  return selector.participants;
}
