import { HMSLogLevel, useHMSActions } from "@100mslive/hms-video-react";
import {
  getDoc,
  onSnapshot,
  QueryDocumentSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import React, { useRef, useState } from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { firebaseAuth, FirebaseCollections } from "../../firebase";
import {
  CanJoinChannelResponseDto,
  JoinChannelRequestDto,
  Participant,
} from "../../sdk";
import { SelectContextFn } from "../../types";
import { Channel } from "../common/channel.entity";
import { useFunction, usePrevious } from "../hooks";
import ChannelAPI from "../services/channel.service";
import { PresenceService } from "../services/presence.service";
import { ParticipantRole } from "../types/enums";

interface Context {
  channel: QueryDocumentSnapshot<Channel>;
  participant: Participant;
  error: any;
  loading: boolean;
  leaveChannel: () => Promise<void>;
  // updateParticipantMetadata: (dto: ParticipantMetadataDto) => Promise<void>;
  canJoin: () => Promise<CanJoinChannelResponseDto>;
  joinChannel: (dto?: JoinChannelRequestDto) => Promise<void>;
}

const channelContext = createContext<Context>({} as Context);

type ChannelProviderProps = {
  channelId: string;
};

const ChannelProvider: React.FC<ChannelProviderProps> = (props) => {
  const { children, channelId } = props;
  const [participant, setParticipant] = useState<Participant>(null);
  const participantRef = useRef(participant);
  const previousParticipant = usePrevious(participant);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const passcodeRef = useRef<string>(null);
  const hmsTokenRef = useRef<string>(null);
  const hmsActions = useHMSActions();

  const [channel, setChannel] = useState<QueryDocumentSnapshot<Channel>>(null);

  const subscriptionsRef = useRef<Unsubscribe[]>([]);

  const connect = useFunction(() => {
    const userId = firebaseAuth.currentUser.uid;

    // Connect to set presence
    PresenceService.connect(channelId, userId);

    const channelSub = onSnapshot(
      FirebaseCollections.channel(channelId),
      (snapshot) => {
        setChannel(snapshot as QueryDocumentSnapshot<Channel>);
      }
    );

    const participantSub = onSnapshot(
      FirebaseCollections.participant(channelId, userId),
      (snapshot) => {
        const participant = snapshot.data();
        setParticipant(participant);
      }
    );

    subscriptionsRef.current.push(channelSub, participantSub);
  });

  const leaveChannel = React.useCallback(async () => {
    subscriptionsRef.current.forEach((sub) => sub());
    subscriptionsRef.current = [];
    PresenceService.disconnect();
    await hmsActions.leave();

    setChannel(null);
    setParticipant(null);
  }, [hmsActions]);

  const refreshHms = React.useCallback(async () => {
    const leavePromise = hmsActions.leave();
    const res = await ChannelAPI.join(channel.id, {
      passcode: passcodeRef.current,
    });

    await leavePromise;
    hmsTokenRef.current = res.appToken;
    // Connect to hms
    hmsActions.join({
      userName: firebaseAuth.currentUser.displayName,
      authToken: res.appToken,
      alwaysRequestPermissions: true,

      settings: {
        isVideoMuted: true,
        isAudioMuted: true,
      },
    });
  }, [channel, participant, hmsTokenRef]);

  React.useEffect(() => {
    const previousRole = previousParticipant?.role;
    const currentRole = participant?.role;
    if (previousRole && currentRole && previousRole !== currentRole) {
      refreshHms();
    }
  }, [refreshHms, previousParticipant, participant]);

  const canJoin = useFunction(async () => {
    try {
      return await ChannelAPI.canJoin(channelId);
    } catch (err) {
      setError(err);
      throw err;
    }
  });

  const joinChannel = useFunction(async (dto?: JoinChannelRequestDto) => {
    try {
      setLoading(true);
      const res = await ChannelAPI.join(channelId, dto);
      const channelDoc = await getDoc(FirebaseCollections.channel(channelId));
      setChannel(channelDoc);

      connect();

      passcodeRef.current = dto.passcode;
      hmsTokenRef.current = res.appToken;

      hmsActions.setLogLevel(__DEV__ ? HMSLogLevel.ERROR : HMSLogLevel.NONE);
      // Connect to hms
      hmsActions.setVideoSettings({ width: 100, height: 100 });
      hmsActions.join({
        userName: firebaseAuth.currentUser.displayName,
        authToken: res.appToken,
        alwaysRequestPermissions: false,
        settings: {
          isVideoMuted: true,
          isAudioMuted: true,
        },
      });
    } catch (error) {
      setError(error);
      throw error;
    } finally {
      setLoading(false);
    }
  });

  return (
    <channelContext.Provider
      value={{
        joinChannel,
        leaveChannel,
        canJoin,
        // updateParticipantMetadata,
        channel,
        participant,
        error,
        loading,
      }}
    >
      {children}
    </channelContext.Provider>
  );
};

export {
  ChannelProvider,
  useChannelStore,
  useChannelActions,
  selectIsModerator,
  selectChannel,
  selectLocalParticipant,
  selectIsJoining,
  selectIsPresenter,
  selectLiveUsers,
  selectTotalUsers,
};

function useChannelActions() {
  return useContextSelector(channelContext, (context) => ({
    joinChannel: context.joinChannel,
    leaveChannel: context.leaveChannel,
    canJoin: context.canJoin,
    // updateParticipantMetadata: context.updateParticipantMetadata,
  }));
}

function useChannelStore<T>(selector: SelectContextFn<T>) {
  return useContextSelector(channelContext, selector);
}

function selectChannel(selector: Context) {
  return selector.channel;
}

function selectLiveUsers(selector: Context) {
  return selector.channel.data().metadata?.liveUsers ?? 0;
}

function selectTotalUsers(selector: Context) {
  return selector.channel.data().metadata?.totalUsers ?? 0;
}

function selectIsJoining(selector: Context) {
  return selector.loading;
}

function selectLocalParticipant(selector: Context) {
  return selector.participant;
}

function selectIsPresenter(selector: Context) {
  return (
    selector.participant?.role === ParticipantRole.MODERATOR ||
    selector.participant?.role === ParticipantRole.PRESENTER
  );
}

function selectIsModerator(selector: Context) {
  return selector.participant?.role === ParticipantRole.MODERATOR;
}
