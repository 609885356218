import { isString } from "lodash";
import React from "react";
import { useTheme } from "react-native-paper";
import { useMessage } from "react-native-paper-message-context";

type NotificationType = {
  success: "success";
  danger: "danger";
};

export const useSnack = () => {
  const { showSnack } = useMessage();
  const theme = useTheme();

  const notify = React.useCallback(
    (message: string) => {
      return showSnack(message, { color: "info" });
    },
    [showSnack]
  );

  const notifyError = React.useCallback(
    (err: any) => {
      const defaultMessage = "Something went wrong.";
      const axiosMsg = err.response?.data?.message;
      const errorMsg = err.message;
      const message = isString(err)
        ? err
        : axiosMsg || errorMsg || defaultMessage;

      return showSnack(message || defaultMessage, {
        color: theme.colors.error,
      });
    },
    [showSnack]
  );

  return { notify, notifyError };
};

export const useDialog = () => {
  const { showDialog } = useMessage();

  const notify = React.useCallback(
    (message: string, type?: NotificationType) => {
      return showDialog(message, { color: `${type}` });
    },
    [showDialog]
  );

  return notify;
};

// export const useConfirmationDialog = () => {
//   const { showSnack, showDialog } = useMessage();
// };
