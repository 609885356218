import React, { DependencyList, EffectCallback, useCallback } from "react";
import { useWindowDimensions } from "react-native";

// Hook
export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = React.useRef<T>();
  // Store current value in ref
  React.useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useScreenSize() {
  const { width, height } = useWindowDimensions();

  const isSmall = width < 700;
  const isMedium = width >= 700 && width < 1000;
  const isLarge = width >= 1000;

  /**
   * Returns true if the screen is in portrait mode
   */
  const isPortrait = height >= width;

  /**
   * Returns true of the screen is in landscape mode
   */
  const isLandscape = width >= height;

  return { isSmall, isMedium, isLarge, isPortrait, isLandscape };
}

export function useWhyDidYouUpdate<T>(name: string, props: T) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = React.useRef<T>();
  React.useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

export function useDebounce<T>(value: T, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );
  return debouncedValue;
}

export function useThrottle<T>(value: T, interval = 500): T {
  const [throttledValue, setThrottledValue] = React.useState<T>(value);
  const lastExecuted = React.useRef<number>(Date.now());

  React.useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return throttledValue;
}

//hook to efficiently instantiate a new instance of some class
export function useMakeClassInstance<T>(
  Class: Constructor<T>,
  ...props: any[]
): T {
  const refObject: { current: null | {} } = React.useRef(null);
  const [instance, setInstance] = React.useState(null);

  const getInstance = (...args: any) => {
    if (refObject.current === null) {
      refObject.current = new Class(...args);
    }
    return refObject.current;
  };

  React.useEffect(() => {
    //if instance not made and all dependencies are truthy,
    if (!instance && props.filter((prop) => prop).length === props.length) {
      setInstance(getInstance(...props));
    }
  }, [...props, instance, setInstance, getInstance]);

  return instance;
}

export function useFunction<T extends (...args: any[]) => any>(callback: T): T {
  return useCallback(callback, []);
}

export type Constructor<T = unknown> = new (...args: any[]) => T;

export function useOnMount(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(effect, []);
}

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? React.useLayoutEffect : React.useEffect;

export function useInterval(callback: () => void, delay: number | null) {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback if it changes.
  useIsomorphicLayoutEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
}
