import { validate as classValidate, ValidationError } from "class-validator";
import React, { useCallback, useReducer, useState } from "react";
import { useWhyDidYouUpdate } from "../client/hooks";

interface Class<T> {
  new (): T;
}

// type ObjectWithKeysOf<T> = { [P in keyof T]: T[P] };

type StringObjectWithKeysOf<T> = { [P in keyof T]: string };

type KeysOf<T> = Extract<keyof T, string>;

type FieldsOf<T> = { [P in keyof T]: T[P] };

type MessagesOf<T> = StringObjectWithKeysOf<T> | undefined;

type UpdateFieldFunction<T> = (key: KeysOf<T>, value: any) => void;

interface ValidateResult<T> {
  data: T | null;
  errors: MessagesOf<T>;
}

type UpdateOneAction<T> = { type: KeysOf<T>; payload: any };
type UpdateAllAction<T> = { type: "UPDATE_ALL"; payload: any };

type State<T> = FieldsOf<T>;

const cleanFromValidator = <T>(dto: T, errors: ValidationError[]) => {
  const dtoKeys = Object.keys(dto) as KeysOf<T>[];

  const msgs = {} as StringObjectWithKeysOf<T>;
  dtoKeys.forEach((key) => {
    const fieldError = errors.find((error) => {
      return error.property === key;
    });
    if (!fieldError) {
      return;
    }
    // Get Constraints
    const { constraints } = fieldError;
    // Get first validation message
    if (!constraints) return;
    msgs[key] = constraints[Object.keys(constraints)[0]];
  });
  return msgs;
};

export const useClassValidator = <T extends object>(DTO: Class<T>) => {
  const dtoRef = React.useRef<T>(new DTO());
  const [msgs, setMsgs] = useState<StringObjectWithKeysOf<T>>();
  const [fieldsState, dispatch] = useReducer(reducer, dtoRef.current);

  function reducer(
    state: State<T>,
    action: UpdateOneAction<T> | UpdateAllAction<T>
  ): State<T> {
    switch (action.type) {
      case "UPDATE_ALL":
        return { ...state, ...action.payload };

      default:
        return { ...state, [action.type]: action.payload };
    }
  }

  const onChange: UpdateFieldFunction<T> = (key, value) => {
    // if fields is empty create it
    dtoRef.current[key] = value;
    // Need to spread to update state

    dispatch({ type: key, payload: value });
  };

  const setFields = (values: T) => {
    dispatch({ type: "UPDATE_ALL", payload: values });
    dtoRef.current = Object.assign(dtoRef.current, values);
  };

  const validate = async (): Promise<ValidateResult<T>> => {
    const results = await classValidate(dtoRef.current);

    const isValid = results.length === 0;

    // If valid clear messages and return
    if (isValid) {
      setMsgs(undefined);
      return { data: dtoRef.current, errors: {} as StringObjectWithKeysOf<T> };
    }

    const validationMsgs = cleanFromValidator<T>(dtoRef.current, results);
    setMsgs(validationMsgs);
    return { data: null, errors: validationMsgs };
  };

  return { fields: fieldsState, onChange, msgs, validate, setFields };
};
