import React from "react";
import {
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from "react-native";

interface GenericProps extends ViewStyle {
  center?: boolean;
  scroll?: boolean;
}

export const Container: React.FC<GenericProps> = (props) => {
  const { children, center, scroll, flexGrow, flex, ...rest } = props;

  const baseStyle: StyleProp<ViewStyle> = StyleSheet.flatten([
    center && {
      justifyContent: "center",
      alignItems: "center",
    },
    //Scroll view dont work with flex:1. hack to keep compatibility
    //only apply hack if scroll is enabled
    {
      backgroundColor: "transparent",
      flexGrow: !scroll ? flexGrow : flexGrow ?? flex,
      flex: !scroll ? flex : 0,
    },

    { ...rest },
  ]);

  if (scroll) {
    return (
      <ScrollView contentContainerStyle={baseStyle}>{children}</ScrollView>
    );
  }

  return <View style={baseStyle}>{children}</View>;
};

export const Column: React.FC<GenericProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Container flexDirection="column" {...rest}>
      {children}
    </Container>
  );
};

export const Row: React.FC<GenericProps> = (props) => {
  const { children, ...rest } = props;
  return (
    <Container flexDirection="row" {...rest}>
      {children}
    </Container>
  );
};

type PaddingProps = {
  size: number;
};

export const Padding: React.FC<PaddingProps> = (props) => {
  const { children, size } = props;
  return <Container padding={size}>{children}</Container>;
};

type SpacerProps = {
  size?: number;
};

export const Spacer: React.FC<SpacerProps> = ({ size = 20 }) => {
  return <View style={{ height: size, width: size }} />;
};

export const FlexSpacer: React.FC<SpacerProps> = ({ size = 20 }) => {
  return <View style={{ flex: 1 }} />;
};

export const Spacer8: React.FC = () => {
  return <Spacer size={8} />;
};
export const Spacer16: React.FC = () => {
  return <Spacer size={16} />;
};

export const Spacer32: React.FC = () => {
  return <Spacer size={32} />;
};
