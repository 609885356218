import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie-player/dist/LottiePlayerLight";
import { Animated, StyleSheet, useWindowDimensions } from "react-native";
import { Portal } from "react-native-paper";
import loaderAnimation from "../../../../assets/loading_animation.json";
import { isWeb } from "../../../client/helpers/utils";
import { useLoadingSelector } from "../loading.provider";

const duration = 300;

export const LoadingOverlay: React.FC<{}> = () => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const loading = useLoadingSelector(({ loading }) => loading);
  const [isVisible, setIsVisible] = useState(false);
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    if (!loading) {
      fadeOut();
    } else {
      setIsVisible(true);
      fadeIn();
    }
  }, [loading]);

  const fadeIn = () => {
    // Will change fadeAnim value to 1 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: duration / 2,
      useNativeDriver: !isWeb,
    }).start();
  };

  const fadeOut = () => {
    // Will change fadeAnim value to 0 in 5 seconds
    Animated.timing(fadeAnim, {
      toValue: 0,
      duration: duration,
      useNativeDriver: !isWeb,
    }).start(() => setIsVisible(false));
  };

  if (!isVisible) {
    return null;
  }
  return (
    <Portal>
      <Animated.View
        style={[styles.wrapper, { opacity: fadeAnim, height, width }]}
      >
        <Lottie
          animationData={loaderAnimation}
          loop
          play
          style={{
            height: 150,
            width: 150,
          }}
        />
      </Animated.View>
    </Portal>
  );
};

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    zIndex: 9999,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  },
});

export function useLoadingDisplay(shouldDisplay: boolean) {
  const setLoading = useLoadingSelector(({ setLoading }) => setLoading);

  useEffect(() => {
    if (shouldDisplay) {
      setLoading(shouldDisplay);
    } else {
      setTimeout(() => {
        setLoading(shouldDisplay);
      }, 500);
    }
  }, [shouldDisplay]);
  return;
}
