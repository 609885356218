import { Container } from "../../helpers/layoutPrimitives";
import { theme } from "../../theme";

const VerticalDivider = () => {
  return (
    <Container
      width={1}
      height={"100%"}
      opacity={0.3}
      backgroundColor={theme.colors.placeholder}
    />
  );
};

export default VerticalDivider;
