import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { IMessage, Send, SendProps } from "react-native-gifted-chat";
import { AnimateDisplay } from "../../../components/animation/AnimateDisplay";
import { theme } from "../../../theme";

const CustomSendButton: React.FC<SendProps<IMessage>> = (props) => {
  return (
    <Send
      {...props}
      containerStyle={{
        height: 40,
        width: 40,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AnimateDisplay>
        <Ionicons name="send" color={theme.colors.primary} size={18} />
      </AnimateDisplay>
    </Send>
  );
};

export default CustomSendButton;
