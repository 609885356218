import { IsEmail, IsNotEmpty, IsString, MinLength } from "class-validator";

export class CredentialsDto {
  @IsString()
  @IsNotEmpty({ message: "Email is required" })
  @IsEmail({}, { message: "Must be a valid email" })
  email: string;

  @IsString()
  @IsNotEmpty({ message: "Password is required" })
  @MinLength(6, { message: "Must be at least 6 characters long" })
  password: string;
}
