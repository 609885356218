import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { JoinScreen } from "../screens/JoinScreen";

export type PublicStackParams = {
  JoinScreen: { channelId: string };
};

const Stack = createNativeStackNavigator<PublicStackParams>();

export function PublicNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="JoinScreen"
        component={JoinScreen}
        options={{ headerShown: false, title: "Join" }}
        initialParams={{ channelId: "test_channel" }}
      />
    </Stack.Navigator>
  );
}
