import React from "react";
import { View } from "react-native";
import { useReactionsList } from "../../../client/hooks/useReactionsList";
import {
  selectChannel,
  useChannelStore,
} from "../../../client/providers/channel.provider";
import { useLayout } from "../../../hooks/useLayout";
import ReactEmoji from "./ReactionEmoji";

const ReactionsView: React.FC = () => {
  const channel = useChannelStore(selectChannel);

  const snapshots = useReactionsList(channel.id);

  const { width, onLayout } = useLayout();

  return (
    <View pointerEvents="none" onLayout={onLayout}>
      {snapshots.map((reaction) => (
        <ReactEmoji
          key={reaction.key}
          emoji={reaction.val().data?.emoji}
          width={width}
        />
      ))}
    </View>
  );
};

export default ReactionsView;
