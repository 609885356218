import { PortalHost } from "@gorhom/portal";
import React from "react";
import { View } from "react-native";
import { useScreenSize } from "../../client/hooks";
import {
  selectIsPresenter,
  useChannelStore,
} from "../../client/providers/channel.provider";
import "../../global.css";
import { Container, FlexSpacer } from "../../helpers/layoutPrimitives";
import { PortalLocation } from "../../helpers/portals";
import { useLayout } from "../../hooks/useLayout";
import ReactionsView from "../../modules/conductor/components/ReactionsView";
import { VideoView } from "../../modules/vxdk/VideoView";
import BottomBar from "../molecules/BottomBar";
import SideView from "./SideView";

const WatchView: React.FC = () => {
  const isPresenter = useChannelStore(selectIsPresenter);
  const { isSmall } = useScreenSize();
  const { width, onLayout } = useLayout();

  const style = isSmall ? {} : { flex: 1 };

  return (
    <Container
      flexDirection={isSmall ? "column" : "row"}
      flex={1}
      overflow="hidden"
    >
      <View onLayout={onLayout} style={style}>
        <FlexSpacer />

        <VideoView height={(width / 16) * 9} width={width} />
        {!isSmall && (
          <View style={{ zIndex: 1 }}>
            <ReactionsView />
          </View>
        )}
        <FlexSpacer />
        {isPresenter && <BottomBar />}
      </View>
      {isSmall && (
        <View style={{ zIndex: 1 }}>
          <ReactionsView />
        </View>
      )}
      <SideView width={isSmall ? "100%" : 325} />
      <PortalHost name={PortalLocation.ROOT} />
    </Container>
  );
};

export default WatchView;
