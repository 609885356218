import { selectPeers, useHMSStore } from "@100mslive/hms-video-react";
import React from "react";
import { Menu } from "react-native-paper";
import {
  selectChannel,
  useChannelStore,
} from "../../client/providers/channel.provider";
import ChannelAPI from "../../client/services/channel.service";
import { Column } from "../../helpers/layoutPrimitives";
import { EnumChangeParticipantRoleDtoRole, ParticipantDto } from "../../sdk";
import DropDownMenu from "../atoms/DropDownMenu";

type ModerationMenuProps = {
  participant: ParticipantDto;
};
const ModerationMenu: React.FC<ModerationMenuProps> = ({ participant }) => {
  const channel = useChannelStore(selectChannel);
  const [showMenu, setShowMenu] = React.useState(false);
  const peers = useHMSStore(selectPeers);

  const handlePromote = async (role: EnumChangeParticipantRoleDtoRole) => {
    setShowMenu(false);
    await ChannelAPI.promote(channel.id, {
      userId: participant.userId,
      role,
    });
  };

  return (
    <Column>
      <DropDownMenu
        icon="dots-vertical"
        onDismiss={() => setShowMenu(false)}
        showMenu={() => setShowMenu(true)}
        visible={showMenu}
      >
        <Menu.Item
          title="Make a Presenter"
          onPress={() => {
            handlePromote(EnumChangeParticipantRoleDtoRole.Presenter);
          }}
        />

        <Menu.Item
          title="Make a Viewer"
          onPress={() => handlePromote(EnumChangeParticipantRoleDtoRole.Viewer)}
        />
      </DropDownMenu>
    </Column>
  );
};

export default ModerationMenu;
