import * as React from "react";
/* SVGR has dropped some elements not supported by react-native-svg: title */
import { memo } from "react";
import Svg, { G, Path, SvgProps } from "react-native-svg";

const BitwildLogo = (props: SvgProps) => (
  <Svg width={35} height={35} viewBox="0 0 675 675" {...props}>
    <G fill="none" fillRule="evenodd">
      <Path
        fill="#CBCBCB"
        d="m265.998 80-151.996 87.646L114 333.353 266 421z"
      />
      <Path fill="#EAEAEA" d="M265.767 80 114 167.698 561 426V250.599z" />
      <Path fill="#FFF" d="M114 508.302 265.767 596 561 425.401V250z" />
    </G>
  </Svg>
);

const Memo = memo(BitwildLogo);
export default Memo;
