import { Ionicons } from "@expo/vector-icons";
import React from "react";
import { StyleSheet } from "react-native";
import { Button as PaperButton } from "react-native-paper";
import { getContrast } from "../../helpers/colors";
import { theme } from "../../theme";
import { IconNames } from "../../types";

type ButtonProps = {
  onPress: () => void;
  icon?: IconNames;
  loading?: boolean;
  // Reverses the order of the content
  // Allows the icon to be on the right side
  reverse?: boolean;
  color?: string;
  mode?: "contained" | "outlined" | "text";
};

const Button: React.FC<ButtonProps> = (props) => {
  const { children, onPress, icon, reverse, mode, color, loading } = props;

  const renderIcon = () => {
    if (!icon) return null;

    return (
      <Ionicons
        color={getContrast(color ?? theme.colors.accent)}
        size={20}
        name={icon}
      />
    );
  };

  return (
    <PaperButton
      mode={mode ?? "contained"}
      color={color}
      loading={loading}
      contentStyle={[
        styles.content,
        reverse && styles.reverseContent,
        icon && styles.withIcon,
      ]}
      uppercase={false}
      onPress={onPress}
      // labelStyle={{ fontSize: 16 }}
      icon={renderIcon}
    >
      {children}
    </PaperButton>
  );
};

export default Button;

const styles = StyleSheet.create({
  content: {
    height: 50,
    marginHorizontal: 8,
  },
  withIcon: {
    justifyContent: "space-between",
  },
  reverseContent: {
    flexDirection: "row-reverse",
  },
});
