import {
  limitToLast,
  onChildAdded,
  onValue,
  query,
  Unsubscribe,
} from "firebase/database";
import React from "react";
import { createContext, useContextSelector } from "use-context-selector";
import { SyncPlayerCommandDto } from "../../conductor/conductor.dto";
import { FirebaseCollections } from "../../firebase";
import { ActionDto } from "../dto/actions.dto";
import { useOnMount } from "../hooks";

//
interface Context {
  syncData: SyncPlayerCommandDto;
  actionData: ActionDto;
}

const conductorContext = createContext<Context>({} as Context);

const ConductorProvider: React.FC = (props) => {
  const [syncData, setSyncData] = React.useState<SyncPlayerCommandDto>(null);
  const [actionData, setActionData] = React.useState<ActionDto>(null);
  const subscriptionsRef = React.useRef<Unsubscribe[]>([]);
  const notFirstReaction = React.useRef<boolean>(false);

  React.useEffect(() => {
    console.log("Conductor provider mounted");
  }, []);

  useOnMount(() => {
    connect("test_channel");

    return () => disconnect();
  });

  const connect = React.useCallback((channelId: string) => {
    console.log("Connecting to Conductor", channelId);
    const syncDbRef = query(
      FirebaseCollections.conductorSync(channelId),
      limitToLast(1)
    );

    const syncSub = onChildAdded(syncDbRef, (snapshot) => {
      const syncData = snapshot.val();

      setSyncData(syncData);
    });

    const actionsRef = FirebaseCollections.conductorBroadcasts(channelId);

    const reactionsSub = onValue(actionsRef, (snapshot) => {
      const actionData = snapshot.val();
      if (notFirstReaction.current) {
        setActionData(actionData);
      } else {
        notFirstReaction.current = true;
      }
    });

    subscriptionsRef.current.push(syncSub, reactionsSub);
  }, []);

  const disconnect = React.useCallback(() => {
    subscriptionsRef.current.forEach((sub) => sub());
    subscriptionsRef.current = [];
  }, []);

  return (
    <conductorContext.Provider value={{ syncData, actionData }}>
      {props.children}
    </conductorContext.Provider>
  );
};

function useConductor<T>(selector: (context: Context) => T) {
  return useContextSelector(conductorContext, selector);
}

export { ConductorProvider, useConductor };
